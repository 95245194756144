import React from "react";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import Button from "@atlaskit/button";

function AtkButtonIconBefore(props: any) {
  return (
    <Button iconBefore={props.iconBefore} appearance={props.appearance} className={`${props.className} AtkButtonIconBefore`} onClick={props.onClick} isDisabled={props.isDisabled} title={props.title}>
      {props.label}
    </Button>
  );
}

export default AtkButtonIconBefore;
