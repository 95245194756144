import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonValues from "../common/utils";
import { Column } from "primereact/column";
import moment from "moment";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import AtkButton from "../AtlaskitControls/AtkButton";
import Header from "../common/Header";
import BillingSidebar from "./BillingSidebar";
import Footer from "../common/Footer";
import BillingMiddleHeader from "./BillingMiddleHeader";

export default function PaymentHistory({ refreshPaymentHistoryFlag }: any) {
  const [stripePaymentHistoryData, setStripePaymentHistoryData] = useState([]);
  const [paymentHistoryLoading, setPaymentHistoryLoading] = useState(false);
  const toast: any = useRef("");
  const navigate = useNavigate();

  useEffect(() => {
    getPaymentHistory();
  }, []);

  const getPaymentHistory = () => {
    setPaymentHistoryLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/payment-history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setStripePaymentHistoryData(response.data.stripePaymentHistoryData);
        setPaymentHistoryLoading(false);
      })
      .catch((error) => {
        setPaymentHistoryLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting payment history",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting payment history",
            life: 3000,
          });
        }
      });
  };

  const handleViewReceipt = (receiptURL: string) => {
    window.open(receiptURL, "_blank");
  };

  const viewReceiptBodyTemplate = (rowData: any) => {
    return rowData.paymentStatus === "Free Credits" ? null : (
      <div className="text-center">
        <AtkButton
          className="pf-primary-btn w-auto"
          type="button"
          label="Receipt"
          onClick={() => {
            handleViewReceipt(rowData.receiptURL);
          }}
        />
      </div>
    );
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="billing-payment-history-main-page">
        <Header pagename="billing-payment-history" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <BillingMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <BillingSidebar pagename="billing-payment-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">              
                {paymentHistoryLoading ? (
                  <>
                    <div className="text-center mt-4">
                      <AtkLoader />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-3">
                      {stripePaymentHistoryData.length > 0 ? (
                        <>
                          <div className="col-12 history-table mt-2">
                            <DataTable value={stripePaymentHistoryData} stripedRows paginator rows={15} dataKey="created" showGridlines scrollable>
                              <Column
                                field="created"
                                header="Transaction Date"
                                body={(rowData) => {
                                  const utcdate = moment.utc(rowData.created);
                                  var localDate = moment(utcdate).local();
                                  return (
                                    <>
                                      <span>{localDate.format("DD/MM/YYYY")}</span> &nbsp; &nbsp;
                                      <span className="m-0 payment-history-time">{localDate.format("h:mm a")}</span>
                                    </>
                                  );
                                }}
                              ></Column>
                              <Column field="agentName" header="Agent Name"></Column>
                              <Column
                                field="amountInCent"
                                header="Credit Amount"
                                body={(rowData) => <span> $ {rowData.amountInCent / 100}</span>}
                              ></Column>
                              <Column header="View Receipt" body={viewReceiptBodyTemplate}></Column>
                              <Column
                                field="paymentStatus"
                                header="Credit Type"
                                body={(rowData) => <span>{rowData.paymentStatus === "Free Credits" ? "Free" : "Paid"}</span>}
                              ></Column>
                            </DataTable>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-center">
                            <AtkMessage appearance="info" messageText="No payment history available" />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
