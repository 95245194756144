import React from "react";
import moment from "moment";

export default function Footer() {
  let year = moment().year();

  return (
    <footer className=" text-14  text-dark pf-footer">
      <div className="row p-2 footer-copyright-div mx-auto">
      {/* <div className="col-lg- col-md- col-sm-4 col-12 privacy-policy-block ">
          <a>Privacy Policy</a>
        </div> */}
        {/* <div className="col-lg-8 col-md-8 col-sm-8 col-12 copyright-block">Insurancegig {year} © All Rights Reserved</div> */}
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 copyright-block">Insurancegig {year} © All Rights Reserved</div>

      </div>
    </footer>
  );
}
