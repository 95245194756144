import PfLogo from "../assets/images/PF_Logo.svg";

export default function MiddleHeader() {

  return (
    <div className="row middle-header-row">
      <div className="col-12">
        
        {/* <div className="middle-header-col show-cursor" onClick={(e: any) => appsRef.current?.toggle(e)}>
          <img src={AddLogo} className="middle-header-logo" />
          <span className="middle-header-title">Add New Apps</span>
        </div> */}
        <div className="middle-header-col active ms-2">
          <img src={PfLogo} className="middle-header-logo" />
          <span className="middle-header-title">Premium Finance</span>
        </div>
      </div>{" "}
    
    </div>
  );
}
