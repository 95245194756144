import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ViewDetailsIcon from "../../../src/assets/images/ViewDetailsIcon.png";
import CommonValues from "../../common/utils";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import ExistingAndFenrisData from "./ExistingAndFenrisData";

export default function FenrisHistory(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [fenrishistorydata, setFenrisHistoryData] = useState<any>([]);
  const [fenrisHistoryLoading, setFenrisHistoryLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [showExistingandFenrisPage, setShowExistingandFenrisPage] =
    useState(false);
  const [selectRowData, setSelectRowData] = useState("");

  useEffect(() => {
    const token = CommonValues.GetToken();

    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getFenrisHistory();
  }, []);

  const getFenrisHistory = () => {
    setFenrisHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/dataenrich/getfenrishistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setFenrisHistoryData(response.data);
        setFenrisHistoryLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting fenris history",
              life: 3000,
            });
          }
        }
        setFenrisHistoryLoading(false);
      });
  };

  const showDetailsAction = (rowData: any) => {
    if (rowData.status == "Success") {
      return (
        <>
          {actionLoading ? (
            <div className="d-flex">
              <AtkLoader />{" "}
            </div>
          ) : (
            <span className="coi-download-icon">
              <img
                src={ViewDetailsIcon}
                height="20"
                width="20"
                className="me-2 show-cursor"
                title="View Details"
                onClick={() => showDetails(rowData)}
              />
            </span>
          )}
        </>
      );
    } else {
      return (
        <>
          <span className=" text-12 quote-not-span">Not Available</span>
        </>
      );
    }
  };

  const showDetails = (rowData: any) => {
    let rowData1 = rowData;
    setSelectRowData(rowData1);
    props.clientnameset(rowData1.insuredName, true, true);
    setShowExistingandFenrisPage(true);
  };
  const FenrisBack = (e: any) => {
    setShowExistingandFenrisPage(false);
  };

  return showExistingandFenrisPage ? (
    <ExistingAndFenrisData
      selectedData={selectRowData}
      FenrisBack={FenrisBack}
      fromHistoryPage="fromHistoryPage"
    ></ExistingAndFenrisData>
  ) : (
    <>
      {fenrisHistoryLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <AtkLoader />{" "}
        </div>
      ) : (
        <div className="row">
          <div className="col-12  history-table mt-3">
            <DataTable
              value={fenrishistorydata}
              paginator
              paginatorTemplate="PrevPageLink PageLinks NextPageLink"
              rows={10}
              // selectionMode="single"
              dataKey="id"
            >
              <Column
                header="Insured Name"
                field="insuredName"
                sortable
              ></Column>
              <Column
                header="Created"
                field="created"
                body={(rowData: any) => {
                  if (rowData.created != null) {
                    return moment
                      .utc(rowData.created, "YYYY-MM-DD")
                      .format("DD-MM-YYYY");
                  }
                }}
              ></Column>
              <Column header="Status" field="status" sortable></Column>
              <Column
                header="Update In AMS"
                field="updatedInAMS"
                sortable
              ></Column>
              <Column header="Preview" body={showDetailsAction}></Column>
              <Column
                header="API Cost"
                field="apiCost"
                body={(rowData: any) => <span>$ {rowData.apiCost == null ? "0" :rowData.apiCost}</span>}
              ></Column>
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
  // }</>
}
