import { FC, useEffect, useState, useRef } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import AtkCheckbox from "../AtlaskitControls/AtkCheckbox";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkButton from "../AtlaskitControls/AtkButton";
import googleIcon from ".././assets/images/g-normal.png";
import microsoftIcon from ".././assets/images/microsoft-avatar.png";
import groupImg from ".././assets/images/Group.png";
import { Toast } from "primereact/toast";
import CommonValues from "../common/utils";
import { useNavigate } from "react-router-dom";
import copyToClipboard from "../assets/images/copy.png";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function Register(props: any) {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [businessName, setBusinessName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [termCondition, setTermCondition] = useState(false);
  const [businessNameError, setBusinessNameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [workEmailError, setWorkEmailError] = useState("");
  const [termConditionError, setTermConditionError] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [registrationLoading, setRegistrationLoading] = useState(false);
  const [parent, setParent] = useState("");
  const [authUserId, setAuthUserId] = useState("");
  const [utmSource, setUtmSource] = useState<string | null>(null);
  const [agencyCode, setAgencyCode] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [errorEmailAddress, setErrorEmailAddress] = useState("");
  const [showAlredyRegisteredUserError, setShowAlredyRegisteredUserError] = useState(false);
  const [showRegisterSuccessMessage, setShowRegisterSuccessMessage] = useState(false);
  const [wholeUrl, setWholeUrl] = useState("");
  const { index } = useParams<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (index) {
      setParent(index);
    }

    // const queryString = require("query-string");
    let queries = new URLSearchParams(window.location.search);

    // let queries = queryString.parse(props.location.search);
    // Social Integration
    const query = new URLSearchParams(window.location.hash);
    if (query != null && query != undefined) {
      var error_msg = query.get("error_description");

      if (error_msg != "" && error_msg != null && error_msg != undefined) {
        window.location.href = "/callback#error=access_denied&error_description=" + error_msg + "&state=signup";
      }

      var access_token = query.get("#access_token");
      if (access_token != "" && access_token != null && access_token != undefined) {
        setLoading(true);

        var config = {
          method: "post",
          url: `${process.env.REACT_APP_AUTHAPI}/validateToken?`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        };
        axios(config)
          .then((response: any) => {
            if (response.data.tenantId != null && response.data.tenantId != "" && response.data.tenantId != undefined) {
              CommonValues.SetValues(
                response.data.token,
                response.data.tenantId,
                response.data.userName,
                response.data.userId,
                response.data.companyName,
                response.data.roleId,
                response.data.avatarName,
                response.data.firstName,
                "",
                response.data.iconURL,
                response.data.hasGIGMarket,
                response.data.slugURL,
                response.data.headerText
              );

              // if (response.data.orgName === null || response.data.orgName === "") {
              //   window.location.href = "/company";
              // } else if ((response.data.roleId == 3 || response.data.roleId == 2) && response.data.hasGIGMarket == true) {
              //   window.location.href = "/" + response.data.slugURL + "/myservices";
              // } else {
              //   window.location.href = "/dashboard";
              // }
              getWizardSetupDetails();
            } else {
              if (response.data.userName != null) {
                setWorkEmail(response.data.userName);
              }
              if (response.data.firstName != null) {
                setFirstName(response.data.firstName);
              }
              if (response.data.lastName != null) {
                setLastName(response.data.lastName);
              }
              if (response.data.auth0UserId != null) {
                setAuthUserId(response.data.auth0UserId);
              }
              setLoading(false);
            }
          })
          .catch((error: any) => {
            setLoading(false);

            console.log(error.response);
          });
      }
    }

    // if (Object.keys(queries).length > 0) {
    //   setUtmSource(queries.utm_source);
    // }

    if (queries.has("utm_source")) {
      setUtmSource(queries.get("utm_source"));
    }
    var isPartnerWorking = localStorage.getItem("IsPartnerWorking");
    var email = localStorage.getItem("Partner_Email");
    var firName = localStorage.getItem("Partner_FirstName");
    var lstName = localStorage.getItem("Partner_LastName");
    var contctNumber = localStorage.getItem("Partner_ContactNumber");
    var agncyName = localStorage.getItem("Partner_AgencyName");
    var agency_code = localStorage.getItem("Partner_AgencyCode");
    var agent_email = localStorage.getItem("Partner_AgentEmail");

    if (isPartnerWorking) {
      if (email != null) {
        setWorkEmail(email);
      }
      if (firName != null) {
        setFirstName(firName);
      }
      if (lstName != null) {
        setLastName(lstName);
      }
      if (contctNumber != null) {
        setContactNumber(contctNumber);
      }
      if (agncyName != null) {
        setBusinessName(agncyName);
      }
      if (agency_code != null) {
        setAgencyCode(agency_code);
      }
      if (agent_email != null) {
        setAgentEmail(agent_email);
      }
      localStorage.removeItem("IsPartnerWorking");
      localStorage.removeItem("Partner_Email");
      localStorage.removeItem("Partner_FirstName");
      localStorage.removeItem("Partner_LastName");
      localStorage.removeItem("Partner_ContactNumber");
      localStorage.removeItem("Partner_AgencyName");
      localStorage.removeItem("Partner_AgencyCode");
      localStorage.removeItem("Partner_AgentEmail");
    }

    if (index != null && index != "") {
      getAgencyDetails(index);
    }
  }, []);

  const getWizardSetupDetails = () => {
    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getwizardsetup`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          insertWizardDetails(false);
        } else {
          if (response.data.isWizardSetupDone) {
            navigate("/landingpage");
            localStorage.setItem("isSetupCompleted", "true");
          } else {
            navigate("/setupwizard");
            localStorage.setItem("isSetupCompleted", "false");
          }
          setLoading(false);
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        setLoading(false);

        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while loading the Setting details", life: 3000 });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while loading the Setting details", life: 3000 });
        }
        // toast.current.show({ severity: "error",  detail: { errorMessage }, life: 3000 });
      });
  };
  const insertWizardDetails = (val: any) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/insertwizardsetup?IsWizardSetupDone=${val}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoading(false);
        if (response.data.isWizardSetupDone) {
          navigate("/landingpage");
          localStorage.setItem("isSetupCompleted", "true");
        } else {
          navigate("/setupwizard");
          localStorage.setItem("isSetupCompleted", "false");
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        setLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        }
      });
  };
  const onAddCromeExtensionClick = () => {
    window.open("https://chrome.google.com/webstore/detail/insurancegig/pidaodnmlepjkmkldnfommgpckflndfg", "_blank");
  };
  const getAgencyDetails = (parentSlug: any) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_AUTHAPI}/api/account/agencyonboarding/tenant?parent=${parentSlug}`,
      headers: {},
    };

    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        console.log(error);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while getting details", life: 3000 });
        }
      });
  };
  const termLabel = (
    <span>
      Click here to indicate that you have read and agree to InsuranceGIG
      <a className="" href="/terms" target="_blank">
        {" "}
        Terms and Conditions
      </a>
      , and &nbsp;
      <a className="" href="/privacypolicy" target="_blank">
        Privacy Policy
      </a>
      .
    </span>
  );
  const onRegisterWithMicrosoft = () => {
    let ddd = window.location.href;
    window.location.href =
      process.env.REACT_APP_Auth0URL +
      "/authorize?response_type=token&client_id=" +
      process.env.REACT_APP_Auth0ClientId +
      "&connection=windowslive&redirect_uri=" +
      ddd +
      "&scope=openid profile email&prompt=login&state=signup&audience=" +
      process.env.REACT_APP_Auth0URL +
      "/api/v2/";
  };
  const onRegisterWithGoogle = () => {
    let ddd = window.location.href;
    window.location.href =
      process.env.REACT_APP_Auth0URL +
      "/authorize?response_type=token&client_id=" +
      process.env.REACT_APP_Auth0ClientId +
      "&connection=google-oauth2&redirect_uri=" +
      ddd +
      "&scope=openid profile email&prompt=login&state=signup&audience=" +
      process.env.REACT_APP_Auth0URL +
      "/api/v2/";
  };
  const checkValidation = () => {
    let returnValue = true;
    setBusinessNameError("");
    setFirstNameError("");
    setLastNameError("");
    setWorkEmailError("");
    setContactNumberError("");
    setTermConditionError("");

    if (businessName == null || businessName == "") {
      setBusinessNameError("Please enter business name");
      returnValue = false;
    }
    if (firstName == null || firstName == "") {
      setFirstNameError("Please enter first name");
      returnValue = false;
    }

    if (lastName == null || lastName == "") {
      setLastNameError("Please enter last name");
      returnValue = false;
    }
    if (workEmail == null || workEmail == "") {
      setWorkEmailError("Please enter work email");
      returnValue = false;
    } else {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (regex.test(workEmail) === false) {
        setWorkEmailError("Please enter a valid work email");
        returnValue = false;
      }
    }
    if (contactNumber != null && contactNumber != "") {
      if (contactNumber.length < 10 || contactNumber.length > 10) {
        setContactNumberError("Please enter valid contact number");
        returnValue = false;
      }
    }
    if (termCondition != true) {
      setTermConditionError("Please accept terms and conditions");
      returnValue = false;
    }
    return returnValue;
  };
  const onAgencyNameChange = (e: any) => {
    const value = e.target.value;

    if (value.length > 255) {
      return;
    }

    setBusinessName(value);

    if (!value.trim()) {
      setBusinessNameError("Please enter business name");
    } else {
      setBusinessNameError("");
    }
  };
  const onFirstNameChange = (e: any) => {
    const value = e.target.value;

    if (value.length > 255) {
      return;
    }
    setFirstName(value);
    if (!value.trim()) {
      setFirstNameError("Please enter first name");
    } else {
      setFirstNameError("");
    }
  };
  const onLastNameChange = (e: any) => {
    const value = e.target.value;

    if (value.length > 255) {
      return;
    }
    setLastName(value);
    if (!value.trim()) {
      setLastNameError("Please enter last name");
    } else {
      setLastNameError("");
    }
  };
  const onWorkemailChange = (e: any) => {
    const value = e.target.value;
    const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (value.length > 255) {
      return;
    }

    setWorkEmail(value);
    if (!value.trim()) {
      setWorkEmailError("Please enter work email");
    } else if (regex.test(value) === false) {
      setWorkEmailError("Please enter a valid work email");
    } else {
      setWorkEmailError("");
    }
  };
  const onContactNumberChange = (e: any) => {
    const value = e.target.value;
    const re = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (re.test(value)) {
      if (value.length > 10) {
        return;
      }
      setContactNumber(e.target.value);
    }
    if (value != null && value != "" && re.test(value)) {
      if (value.length < 10 || value.length > 10) {
        setContactNumberError("Please enter valid contact number");
      } else {
        setContactNumberError("");
      }
    } else {
      setContactNumberError("");
    }
  };
  const onTermConditionChange = (e: any) => {
    setTermCondition(!termCondition);

    if (!termCondition != true) {
      setTermConditionError("Please accept terms and conditions");
    } else {
      setTermConditionError("");
    }
  };
  const onRegisterClick = () => {
    if (checkValidation()) {
      OnAgentRegister("");
    }
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      onRegisterClick();
    }
  };
  const onLoginLinkClicked = () => {
    navigate("/");
  };
  const OnAgentRegister = (uploadedLogo: any) => {
    var data = JSON.stringify({
      FirstName: firstName,
      LastName: lastName,
      AgencyName: businessName,
      ContactNo: contactNumber,
      Email: workEmail,
      AgencyLogo: uploadedLogo,
      AcceptTerms: termCondition,
      Parent: parent,
      UtmSource: utmSource,
      AgencyCode: agencyCode,
      AgentEmail: agentEmail,
      Auth0UserId: authUserId,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_AUTHAPI}/api/account/agencyonboarding/insert`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    setRegistrationLoading(true);

    axios(config)
      .then((response: any) => {
        setRegistrationLoading(false);
        setShowRegisterSuccessMessage(true);
        setWholeUrl(`${process.env.REACT_APP_UiUrl}` + `/agentonboarding/${response.data}`);
        setShowAlredyRegisteredUserError(false);
        setErrorEmailAddress("");

        setRegistrationLoading(false);
      })
      .catch((error: any) => {
        setRegistrationLoading(false);

        if (error.response != null) {
          if (error.response.status === 404) {
            // toast.current.show({ severity: "warning", detail: "Unknown error while SupportLogin", life: 3000 });
            setErrorEmailAddress(workEmail);
            setShowAlredyRegisteredUserError(true);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting  details!", life: 3000 });
          }
        }
      });
  };
  const onLinkClick = () => {
    window.open(wholeUrl, "_blank");
  };
  const CopyToClipBoardClicked = () => {
    navigator.clipboard
      .writeText(wholeUrl)
      .then(() => {
        toast.current.show({ severity: "success", detail: "Copied!", life: 3000 });
      })
      .catch(() => {
        toast.current.show({ severity: "error", detail: "Failed to copy", life: 3000 });
      });
  };
  const onLoginClick = () => {
    navigate("/");
  };
  return (
    <div className="login-page">
      <Toast ref={toast} />

      <Header pagename="register" />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
        <div className="register-box">
          <div className="row register-header mx-auto p-3">
            <div className="col-12 ">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12  col-5 d-flex  align-items-center p-0">
                  <img src={groupImg} className="me-2" />
                  <span className="text-16">
                    <b>Registration</b>
                  </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12  col-7 sepearte-page-header-div ">
                  <span>
                    Already have an account?{" "}
                    <a onClick={onLoginClick} target="_blank" className="registration-related-link">
                      Login
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {showRegisterSuccessMessage ? (
            <div className="row">
              <div className="col-12">
                <div className="text-center p-4">
                  <h3>Thank you for registering!</h3>
                  <p className="text-16">
                    {authUserId == "" ? (
                      <>
                        You’ve successfully registered with InsuranceGIG. Please check your email for instructions on how to set up your password and
                        login.
                        <br />
                        <br />
                      </>
                    ) : null}
                    Here is your unique {businessName} registration &nbsp;
                    <a onClick={onLinkClick} style={{ cursor: "pointer", display: "inline-block" }} className="mr-1 ml-1">
                      link &nbsp;
                    </a>
                    <span className="copyclipboard-div">
                      <img src={copyToClipboard} onClick={CopyToClipBoardClicked} style={{ cursor: "pointer" }} height="20px" width="20px" />
                    </span>
                    . Use it to invite other agents to register.
                    <br />
                    <p>
                      Be sure to{" "}
                      <a onClick={onAddCromeExtensionClick} className="registration-related-link" style={{ display: "inline-block" }}>
                        {" "}
                        download{" "}
                      </a>{" "}
                      our Chrome Extension for easy access to insurtech apps, including Premium Finance.
                    </p>
                    Reach out to &nbsp;
                    <a href="mailto: support@insurancegig.com" className=" " style={{ display: "inline-block" }}>
                      support@insurancegig.com
                    </a>{" "}
                    if you have any questions.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              {showAlredyRegisteredUserError ? (
                <div className="row mx-auto">
                  <div className="col-12 already-user-exist-message">
                    <p>
                      {errorEmailAddress} already registered. Please{" "}
                      <a onClick={onLoginLinkClicked} className="text-decoration-underline show-cursor">
                        login
                      </a>
                      .
                    </p>
                  </div>
                </div>
              ) : null}
              <div className="row mx-auto d-flex align-items-center px-3 py-4">
                <div className=" col-4 text-start">
                  <label>
                    Business Name<span className="text-danger "> *</span>{" "}
                  </label>
                </div>
                <div className={businessNameError.length > 0 ? "col-8  validation-div" : "col-8 "}>
                  <AtkTextField
                    type="text"
                    id="txtBusinessName"
                    name="businessName"
                    onChange={onAgencyNameChange}
                    value={businessName}
                    className="input-field"
                    placeholder="Enter business name"
                    onKeyDown={onEnterClick}
                  />
                </div>
                <div className=" col-4 text-start "></div>
                <div className="col-8 ">
                  <span className="text-danger text-12 ">{businessNameError}</span>
                </div>

                <div className=" col-4 text-start mt-3">
                  <label>
                    First Name<span className="text-danger "> *</span>{" "}
                  </label>
                </div>
                <div className={firstNameError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                  <AtkTextField
                    type="text"
                    id="txtfirstName"
                    name="firstName"
                    onChange={onFirstNameChange}
                    value={firstName}
                    className="input-field"
                    placeholder="Enter first name"
                    onKeyDown={onEnterClick}
                  />
                </div>
                <div className=" col-4 text-start "></div>
                <div className="col-8 ">
                  <span className="text-danger text-12 ">{firstNameError}</span>
                </div>
                <div className=" col-4 text-start mt-3">
                  <label>
                    Last Name<span className="text-danger "> *</span>{" "}
                  </label>
                </div>
                <div className={lastNameError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                  <AtkTextField
                    type="text"
                    id="txtLastName"
                    name="lastName"
                    onChange={onLastNameChange}
                    value={lastName}
                    className="input-field"
                    placeholder="Enter last name"
                    onKeyDown={onEnterClick}
                  />
                </div>
                <div className=" col-4 text-start "></div>
                <div className="col-8 ">
                  <span className="text-danger text-12 ">{lastNameError}</span>
                </div>
                <div className=" col-4 text-start mt-3">
                  <label>
                    Work Email<span className="text-danger "> *</span>{" "}
                  </label>
                </div>
                <div className={workEmailError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                  <AtkTextField
                    type="text"
                    id="txtWorkEmail"
                    name="workEmail"
                    onChange={onWorkemailChange}
                    value={workEmail}
                    className="input-field"
                    placeholder="Enter work email"
                    onKeyDown={onEnterClick}
                  />
                </div>
                <div className=" col-4 text-start "></div>
                <div className="col-8 ">
                  <span className="text-danger text-12 ">{workEmailError}</span>
                </div>
                <div className=" col-4 text-start mt-3 ">
                  <label>Contact Number</label>
                </div>
                <div className={contactNumberError.length > 0 ? "col-8  validation-div mt-3" : "col-8 mt-3"}>
                  <AtkTextField
                    type="text"
                    id="txtContact"
                    name="contactNumber"
                    onChange={onContactNumberChange}
                    value={contactNumber}
                    className="input-field"
                    placeholder="Enter contact number"
                    onKeyDown={onEnterClick}
                  />
                </div>
                <div className=" col-4 text-start "></div>
                <div className="col-8 ">
                  <span className="text-danger text-12 ">{contactNumberError}</span>
                </div>
                <div className="col-12 mt-3 text-start d-flex justify-content-center align-items-center">
                  <AtkCheckbox label={termLabel} className="" name={termCondition} isChecked={termCondition} onChange={onTermConditionChange} />
                </div>
                <div className="col-12 text-start ">
                  <span className="text-danger text-12 ">{termConditionError}</span>
                </div>

                <div className="col-12 text-center my-3">
                  {registrationLoading || loading ? (
                    <div className="p-1">
                      <AtkLoader />{" "}
                    </div>
                  ) : (
                    <AtkButton label="Register" onClick={onRegisterClick} className="pf-primary-btn w-auto" />
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-6 google-box ">
                  <span onClick={onRegisterWithGoogle} className="social-link-block">
                    <span className="me-1 ">
                      <img src={googleIcon} alt="" />
                    </span>
                    <span className=" text-14 ">
                      <span>Register with Google</span>
                    </span>
                  </span>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-6 microsoft-box">
                  <span onClick={onRegisterWithMicrosoft} className="social-link-block">
                    <span className="me-1">
                      <img src={microsoftIcon} alt="" />
                    </span>
                    <span className=" text-14 ">
                      <span>Register with Microsoft</span>
                    </span>
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}
