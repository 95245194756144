import { CardCvcElement, CardExpiryElement, CardNumberElement, ElementsConsumer } from "@stripe/react-stripe-js";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import CommonValues from "../common/utils";
import { PaymentMethod } from "@stripe/stripe-js";
import PrefilledCreditCardNumber from "../assets/images/PrefilledCreditCardNumber.svg";
import { getByPlaceholderText } from "@testing-library/react";

function StripePaymentMethod(props: any) {
  const toast: any = useRef("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [cardDetailsLoading, setCardDetailsLoading] = useState(false);
  const [prefillCardNumber, setPrefillCardNumber] = useState("");
  const [prefillExpiry, setPrefillExpiry] = useState("");
  const [prefillCardCVC, setPrefillCardCVC] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [editCardDetailsFlag, setEditCardDetailsFlag] = useState(true);
  const [prefillCardCvcError, setPrefillCardCvcError] = useState("");
  const [prefillExpiryError, setPrefillExpiryError] = useState("");
  const [prefillCardNumberError, setPrefillCardNumberError] = useState("");
  const [isCvcComplete, setIsCvcComplete] = useState(false);
  const [isExpiryComplete, setIsExpiryComplete] = useState(false);
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);

  const { stripe, elements } = props;
  const cardElementOptions = {
    showIcon: true,
    disableLink: false,

    style: {
      base: {
        color: "#676A6C",
        fontSize: "16px",
        fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "red",
        fontSize: "16px",
      },
    },
  };
  const navigate = useNavigate();

  useEffect(() => {
    getCardDetails();
  }, []);

  const getCardDetails = () => {
    setCardDetailsLoading(true);
    setPrefillCardNumberError("");
    setPrefillExpiryError("");
    setPrefillCardCvcError("");
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/card-details`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance("" + response.data.stripeCardDetails.currentBalance / 100);
          if (
            response.data.stripeCardDetails.cardNumber !== undefined &&
            response.data.stripeCardDetails.expiryMonth !== undefined &&
            response.data.stripeCardDetails.expiryYear !== undefined
          ) {
            setPrefillCardNumber("**** ***** **** " + response.data.stripeCardDetails.cardNumber);
            setPrefillExpiry(response.data.stripeCardDetails.expiryMonth + " / " + response.data.stripeCardDetails.expiryYear);
            setPrefillCardCVC("***");
            setEditCardDetailsFlag(false);
          }
        }
        setCardDetailsLoading(false);
      })
      .catch((error) => {
        setCardDetailsLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  const createCustomer = async (id: any, paymentMethod: PaymentMethod) => {
    var token = CommonValues.GetToken();
    let data = JSON.stringify({
      TokenId: id,
      AutoPay: false,
      PaymentMethodId: paymentMethod.id,
      CardBrand: paymentMethod.card?.brand,
      CardExpMonth: paymentMethod.card?.exp_month,
      CardExpYear: paymentMethod.card?.exp_year,
      CardLast4Digit: paymentMethod.card?.last4,
      PaymentMethodResponseJSON: JSON.stringify(paymentMethod),
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (response.data.success === true) {
          toast.current.show({
            severity: "success",
            detail: "Credit card details saved successfully",
            life: 3000,
          });
          setSubmitLoading(false);
          getCardDetails();
        } else {
          toast.current.show({
            severity: "error",
            detail: response.data.errorMessage,
            life: 3000,
          });
          setSubmitLoading(false);
          getCardDetails();
        }
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        if (error.response.status === 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error occured while creating stripe customer.",
            life: 3000,
          });
        }
      });
  };

  const createPaymentMethod = async (cardToken: any) => {
    const paymentMethod = await stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      })
      .then(({ paymentMethod }: any) => {
        if (!cardToken.error) {
          createCustomer(cardToken.token.id, paymentMethod);
        }
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        toast.current.show({
          severity: "error",
          detail: "Error creating Payment Method",
          life: 3000,
        });
      });
  };
  const checkValidation = () => {
    let returnValue = true;
    setPrefillCardNumberError("");
    setPrefillExpiryError("");
    setPrefillCardCvcError("");

    if (!isCardNumberComplete) {
      setPrefillCardNumberError("Please enter  the 16-digit card number");
      returnValue = false;
    }

    if (!isExpiryComplete) {
      setPrefillExpiryError("Please enter expiration month / year on card");
      returnValue = false;
    }

    if (!isCvcComplete) {
      setPrefillCardCvcError("Please enter the complete 3-digit CVC code on the card.");
      returnValue = false;
    }

    return returnValue;
  };
  const onCardCvcChange = (event: any) => {    
    if (event.error) {
      setPrefillCardCvcError(event.error.message);
    }
    setIsCvcComplete(event.complete);
  };
  const onCardNumberChange = (event: any) => {
    if (event.error) {
      setPrefillCardNumberError(event.error.message);
    }
    setIsCardNumberComplete(event.complete);
  };
  const onCardExpiryChange = (event: any) => {
    if (event.error) {
      setPrefillExpiryError(event.error.message);
    }
    setIsExpiryComplete(event.complete);
  };
  const handleSaveCardDetails = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardNumber = elements.getElement(CardNumberElement);
    if (checkValidation()) {
      if (cardNumber) {
        setSubmitLoading(true);
        await stripe
          .createToken(cardNumber)
          .then((response: any) => {
            createPaymentMethod(response);
          })
          .catch((error: any) => {
            setSubmitLoading(false);
          });
      }
    }
  };

  const handleEditCardDetails = () => {
    setEditCardDetailsFlag(true);
  };

  const handleCancelEditingCardDetails = () => {
    setPrefillCardNumberError("");
    setPrefillExpiryError("");
    setPrefillCardCvcError("");
    setEditCardDetailsFlag(false);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="billing-payment-details">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="row form-group text-start mt-3">
              <div className="col-md-12 text-16">
                <span>
                  <b>
                    {`${prefillCardNumber.length > 0 && prefillExpiry.length > 0 ? `Update ` : `Add `}`}
                    Payment Method
                  </b>
                </span>
              </div>
            </div>
            {cardDetailsLoading ? (
              <div className="text-center mt-4">
                <AtkLoader />
              </div>
            ) : (
              <>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3 text-start cred-title">
                  <label>
                    <b>Current Balance</b>
                  </label>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 my-2">
                  <div className="input-group">
                    <span className="input-group-text">$</span>
                    <AtkTextField className="input-field form-control p-0" type="text" id="text" value={currentBalance} isDisabled={true} />
                  </div>
                </div>

                <form className="mt-3" id="payment-form">
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-2 text-start cred-title">
                    <label>
                      <b>Card Number</b>
                    </label>
                  </div>
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 mt-2"
                  
                  >
                    {prefillCardNumber.length === 0 || editCardDetailsFlag ? (
                      <CardNumberElement
                        id="card-element"
                        className={` form-control ${prefillCardNumberError.length > 0 ? "stripe-validation" : ""}`}
                        onChange={onCardNumberChange}
                        options={cardElementOptions}
                      />
                    ) : (
                      <AtkTextField
                        type="text"
                        id="cardNumber"
                        value={prefillCardNumber}
                        isDisabled={true}
                        elemAfterInput={<img className="mx-2" src={PrefilledCreditCardNumber}></img>}
                      />
                    )}
                  </div>
                  {prefillCardNumberError.length > 0 || editCardDetailsFlag ? (
                    <div className="text-start m-0 p-0">
                      <span className="text-12 text-danger">{prefillCardNumberError}</span>
                    </div>
                  ) : null}
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3 text-start cred-title">
                    <label>
                      <b>Card Expiry</b>
                    </label>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                    {prefillExpiry.length === 0 || editCardDetailsFlag ? (
                      <CardExpiryElement
                        id="card-element"
                        className={` form-control ${prefillExpiryError.length > 0 ? "stripe-validation" : ""}`}
                        onChange={onCardExpiryChange}
                        options={cardElementOptions}
                      />
                    ) : (
                      <AtkTextField type="text" id="cardNumber" value={prefillExpiry} isDisabled={true} />
                    )}
                  </div>
                  {prefillExpiryError.length > 0 || editCardDetailsFlag ? (
                    <div className="text-start">
                      <span className="text-12 text-danger">{prefillExpiryError}</span>
                    </div>
                  ) : null}

                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3 text-start cred-title">
                    <label>
                      <b>Card CVC</b>
                    </label>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                    {prefillCardNumber.length === 0 || editCardDetailsFlag ? (
                      <CardCvcElement
                        id="card-element"
                        onChange={onCardCvcChange}
                        className={` form-control ${prefillCardCvcError.length > 0 ? "stripe-validation" : ""}`}
                        options={cardElementOptions}
                      />
                    ) : (
                      <AtkTextField type="text" id="cardCVC" name="prefillCardCVC" value={prefillCardCVC} isDisabled={true} />
                    )}
                  </div>
                  {prefillCardCvcError.length > 0 || editCardDetailsFlag ? (
                    <div className="text-start">
                      <span className="text-12 text-danger">{prefillCardCvcError}</span>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-md-12 text-end mt-3">
                      {editCardDetailsFlag ? (
                        <>
                          <div className="row">
                            <div className="col-md-5"></div>
                            <div className={`col-md-${prefillCardNumber.length > 0 && prefillExpiry.length > 0 ? `4` : `7`} text-end`}>
                              {submitLoading ? (
                                <AtkLoader />
                              ) : (
                                <AtkButton
                                  className="pf-primary-btn w-auto mx-2"
                                  label="Save Details"
                                  type="submit"
                                  onClick={handleSaveCardDetails}
                                ></AtkButton>
                              )}
                            </div>
                            {prefillCardNumber.length > 0 && prefillExpiry.length > 0 ? (
                              <div className="col-md-3">
                                <AtkButton
                                  className="pf-primary-btn w-auto"
                                  label="Cancel"
                                  type="button"
                                  onClick={handleCancelEditingCardDetails}
                                ></AtkButton>{" "}
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <AtkButton
                          className="pf-primary-btn w-auto"
                          label="Edit card details"
                          type="button"
                          onClick={handleEditCardDetails}
                        ></AtkButton>
                      )}
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </>
  );
}

export default function InjectedStripePaymentMethod() {
  return <ElementsConsumer>{({ stripe, elements }) => <StripePaymentMethod stripe={stripe} elements={elements} />}</ElementsConsumer>;
}
