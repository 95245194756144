import { useEffect, useState, useRef } from "react";
import Footer from "../../common/Footer";
import Sidebar from "../../common/Sidebar";
import MiddleHeader from "../../common/MiddleHeader";
import Header from "../../common/Header";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import moment from "moment";

export default function QuickQuoteConfirmation() {
  const [term, setTerm] = useState<any>([""]);
  const [effectiveDate, setEffectiveDate] = useState<any>([""]);
  const [policyNumber, setPolicyNumber] = useState([""]);
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [businessProfile, setBusinessProfile] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [search, setSearch] = useState([""]);
  const [carrierSearch, setCarrierSearch] = useState<any>([]);
  const [carrierAddress, setCarrierAddress] = useState([""]);
  const [carrierCity, setCarrierCity] = useState([""]);
  const [carrierState, setCarrierState] = useState([""]);
  const [carrierZip, setCarrierZip] = useState([""]);
  const [carrierCountry, setCarrierCountry] = useState(["USA"]);
  const [gaName, setGaName] = useState([""]);
  const [gaAddress, setGaAddress] = useState([""]);
  const [gaCity, setGaCity] = useState([""]);
  const [gaState, setGaState] = useState([""]);
  const [gaZip, setGaZip] = useState([""]);
  const [gaCountry, setGaCountry] = useState([""]);
  const [brokerFee, setBrokerFee] = useState<any>([]);
  const [currentPremiumsArray, setCurrentPremiumsArray] = useState<any>([""]);
  const [taxes, setTaxes] = useState<any>([]);
  const [fees, setFees] = useState<any>([]);
  const [minimumEarned, setminimumEarned] = useState<any>([]);
  const [sstate, setSstate] = useState("");

  return (
    <div className="quick-quote-confirmation-main-page">
      <Header pagename="QuickQuoteConfirmationPage" />
      <div className="content-wrapper ">
        <div className="wrapper-main-row mx-auto wrapper-below-div">
          <div className="row main-content-row mx-auto ">
            <div className="col-lg-12 col-md-12 col-sm-12 rounded-1 main-content-col ">
              <div className="row">
                <div className="col policy-name-tab mt-4 ">
                  <span className="policy-type">Policy Type</span>
                  <span
                    className="policy-name-span show-cursor"
                    //   onClick={() => onSubPolicySelect(index)}
                  >
                    Worker's Compensation
                    {/* {code.code} */}
                  </span>
                </div>
              </div>

              <div className="row subpolicy-block mt-3 mb-2 ">
                <>
                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Policy Number</label>
                    <span className="text-danger ">*</span>

                    <AtkTextField className="input-field" type="text" id="text" value={policyNumber[0]} placeholder="Enter policy number" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Effective Date</label>
                    <span className="text-danger ">*</span>

                    <AtkTextField
                      className="input-field "
                      type="date"
                      id="text"
                      value={moment(effectiveDate[0]).format("YYYY-MM-DD")}
                      placeholder="Select effective date"
                    />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Current Premium </label>
                    <span className="text-danger ">*</span>
                    <div className="input-group ">
                      <span className="input-group-text">$</span>
                      <AtkTextField
                        className="input-field form-control p-0"
                        type="number"
                        id="text"
                        value={currentPremiumsArray[0]}
                        placeholder="Enter current premium"
                      />
                    </div>
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2 position-relative">
                    <label>Coverage Code</label>
                    <span className="text-danger ">*</span>

                    <AtkTextField
                      className="input-field policydetails "
                      type="text"
                      id="text"
                      value={search[0]}
                      placeholder="Search and select coverage codes..."
                    />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Taxes</label>
                    <div className="input-group ">
                      <span className="input-group-text">$</span>
                      <AtkTextField className="input-field form-control p-0" type="number" id="text" value={taxes[0]} placeholder="Enter taxes" />
                    </div>
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Fees</label>
                    <div className="input-group ">
                      <span className="input-group-text">$</span>
                      <AtkTextField className="input-field form-control p-0" type="number" id="text" value={fees[0]} placeholder="Enter fees" />
                    </div>
                  </div>
                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Minimum Earned %</label>
                    <div className="input-group ">
                      <span className="input-group-text">%</span>
                      <AtkTextField className="input-field form-control p-0" type="number" id="text" value={minimumEarned[0]} />
                    </div>
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2 position-relative">
                    <label>Carrier Name</label>
                    <span className="text-danger ">*</span>

                    <AtkTextField
                      className="input-field policydetails"
                      type="text"
                      id="text"
                      value={carrierSearch[0]}
                      placeholder="Search and select carrier name..."
                    />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Carrier Address</label>
                    <AtkTextField className="input-field" type="text" id="text" value={carrierAddress[0]} placeholder="Enter carrier address" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Carrier City</label>
                    <AtkTextField className="input-field" type="text" id="text" value={carrierCity[0]} placeholder="Enter carrier city" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Carrier State</label>
                    <AtkTextField className="input-field" type="text" id="text" value={carrierState[0]} placeholder="Enter carrier state" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Carrier Zip </label>
                    <span className="text-danger ">*</span>

                    <AtkTextField className="input-field" type="text" id="text" value={carrierZip[0]} placeholder="Enter carrier zip" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Carrier Country </label>

                    <AtkTextField className="input-field" type="text" id="text" value={carrierCountry[0]} placeholder="Enter carrier country" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2 position-relative">
                    <label>General Agency Name</label>
                    <AtkTextField className="input-field" type="text" id="text" value={gaName[0]} placeholder="Enter general agency name" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>General Agency Address</label>

                    <AtkTextField className="input-field" type="text" id="text" value={gaAddress[0]} placeholder="Enter general agency address" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>General Agency City</label>

                    <AtkTextField className="input-field" type="text" id="text" value={gaCity[0]} placeholder="Enter general agency city" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>General Agency State</label>

                    <AtkTextField className="input-field" type="text" id="text" value={gaState[0]} placeholder="Enter general agency state" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>General Agency Zip</label>

                    <AtkTextField className="input-field" type="text" id="text" value={gaZip[0]} placeholder="Enter general agency zip" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>General Agency Country</label>

                    <AtkTextField className="input-field" type="text" id="text" value={gaCountry[0]} placeholder="Enter general agency country" />
                  </div>
                  <div className="form-group col-lg-3 col-md-4 col-sm-12 pb-2  ">
                    <label>Broker Fee</label>
                    <div className="input-group ">
                      <span className="input-group-text">$</span>
                      <AtkTextField className="input-field form-control p-0" type="text" id="text" value={brokerFee} placeholder="Enter broker fee" />
                    </div>
                    <span className="text-danger text-12">{}</span>
                  </div>
                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Term</label>
                    <span className="text-danger ">*</span>

                    <AtkTextField className="input-field" type="text" id="text" value={term} placeholder="Enter term" />
                  </div>
                </>
              </div>
              <div className="col-12 divider "></div>

              <div className="row showmore-block ">
                <>
                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Business Profile Name </label>
                    <span className="text-danger ">*</span>

                    <AtkTextField className="input-field" type="text" id="text" value={businessProfile} placeholder="Enter business profile name" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Address Line No 1</label>
                    <span className="text-danger ">*</span>

                    <AtkTextField className="input-field" type="text" id="text" value={addLine1} placeholder="Enter address line no 1" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Address Line No 2</label>

                    <AtkTextField className="input-field" type="text" id="text" value={addLine2} placeholder="Enter address line no 2" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>City</label>
                    <span className="text-danger ">*</span>

                    <AtkTextField className="input-field" type="text" id="text" value={city} placeholder="Enter city" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>State</label>
                    <span className="text-danger ">*</span>

                    <AtkTextField className="input-field" type="text" id="text" value={sstate} placeholder="Enter state" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Country</label>
                    <span className="text-danger ">*</span>

                    <AtkTextField className="input-field" type="text" id="text" value={country} placeholder="Enter country" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Zip Code</label>
                    <span className="text-danger ">*</span>

                    <AtkTextField className="input-field" type="text" id="text" value={zipCode} placeholder="Enter zip code" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Email Address</label>

                    <AtkTextField className="input-field" type="text" id="text" value={businessEmail} placeholder="Enter email address" />
                  </div>

                  <div className="form-group col-lg-3 col-md-4 col-sm-12 mb-2">
                    <label>Phone Number</label>

                    <AtkTextField className="input-field" type="text" id="text" value={businessPhoneNumber} placeholder="Enter phone number" />
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
