import React, { FC, useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DownloadIcon from "../../assets/images/icon _download_.png";
import CommonValues, { Logout } from "../../common/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import { saveAs } from "file-saver";
import { Toast } from "primereact/toast";
import moment from "moment";

export default function COIHistory(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [historyloading, setHistoryLoading] = useState(true);
  const [coihistorydata, setCOIHistoryData] = useState<any>([]);
  const [downloadloading, setDownloadLoading] = useState(false);
  const [submissionId, setSubmissionId] = useState();
  const [fileurl, setFileUrl] = useState();

  useEffect(() => {
    const token = CommonValues.GetToken();

    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getCOIHistory();

  }, [])

  const getCOIHistory = () => {
    setHistoryLoading(true)
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/getcoihistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setCOIHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting coi history", life: 3000 });
          }
        }
        setHistoryLoading(false)
      });
  };

  const onDownloadClick = (rowData: any) => {
    setDownloadLoading(true)
    let submissionId = rowData.submissionId;
    setSubmissionId(submissionId);
    var token = CommonValues.GetToken();
    axios({
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/downloadcsvcoifile/${rowData.submissionId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: any) => {
        const link = document.createElement('a')
        link.href = response.data;
        link.click()
        link.remove()
        setDownloadLoading(false)
      })
      .catch((error) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while downloading the file", life: 3000 });
          }
        }
        setDownloadLoading(false)
      }

      );
  }

  const refresHistoryData = () => {
    getCOIHistory();
  }

  const download = (rowData: any) => {
    if (rowData.status == "Completed") {
      return (
        <>
          {rowData.submissionId == submissionId && downloadloading ? <div className="d-flex">
            <AtkLoader />{" "}
          </div> :
            <span className="coi-download-icon">

              <img src={DownloadIcon} height="20" width="20" className="me-2" title="Download CSV" alt="Download" onClick={() => onDownloadClick(rowData)} />

            </span>
          }
        </>
      );
    }
    else {
      return (
        <>
          <span className=" text-12 quote-not-span">
            Not Available
          </span>
        </>
      );
    }
  };

  return (
    <div className="row">
      <Toast ref={toast} />
      <div className="col-12 history-refresh-button">
        <AtkButtonIconBefore
          label="Refresh"
          className="pf-secondary-btn"
          onClick={() => {
            refresHistoryData();
          }}
        />
      </div>
      <div className="col-12  history-table mt-3">
        {historyloading ?
          <div className="d-flex justify-content-center align-items-center">
            <AtkLoader />{" "}
          </div> :
          <DataTable
            value={coihistorydata}
            paginator
            paginatorTemplate="PrevPageLink PageLinks NextPageLink"
            rows={10}
            selectionMode="single"
          >

            <Column
              header="Created Date"
              field="created"
              
              filter
              body={(rowData: any) => {
                if (rowData.created != null || rowData.created !== "") {
                  return moment.utc(rowData.created, "YYYY-MM-DD").format("DD-MM-YYYY");
                }
              }}
            ></Column>
            <Column header="File Name" field="fileName" sortable filter></Column>
            <Column header="Status" field="status"  sortable filter ></Column>
            <Column header="Submission Id" field="submissionId" sortable  filter></Column>
            <Column header="Action" body={download} ></Column>
          </DataTable>
        }
      </div>
    </div>
  )
}