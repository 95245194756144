import React from 'react'
import { Checkbox } from '@atlaskit/checkbox';
import __noop from '@atlaskit/ds-lib/noop';

export default function AtkCheckbox(props:any) {
  return (
    <Checkbox
    value={props.value}
    label={props.label}
    onChange={props.onChange}
    name={props.name}
    testId="cb-default"
    isChecked={props.isChecked}
  />  )
}
