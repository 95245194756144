import AtkTextField from "../../AtlaskitControls/AtkTextField";
import moment from "moment";

export default function NewInsuredPage(props: any) {
  const Allstate = [
    { name: "Alaska", code: "AK" },
    { name: "Alabama", code: "AL" },
    { name: "Arkansas", code: "AR" },
    { name: "Arizona", code: "AZ" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "DistrictofColumbia", code: "DC" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Iowa", code: "IA" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Massachusetts", code: "MA" },
    { name: "Maryland", code: "MD" },
    { name: "Maine", code: "ME" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Missouri", code: "MO" },
    { name: "Mississippi", code: "MS" },
    { name: "Montana", code: "MT" },
    { name: "NorthCarolina", code: "NC" },
    { name: "NorthDakota", code: "ND" },
    { name: "Nebraska", code: "NE" },
    { name: "NewHampshire", code: "NH" },
    { name: "NewJersey", code: "NJ" },
    { name: "NewMexico", code: "NM" },
    { name: "Nevada", code: "NV" },
    { name: "NewYork", code: "NY" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "RhodeIsland", code: "RI" },
    { name: "SouthCarolina", code: "SC" },
    { name: "SouthDakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Virginia", code: "VA" },
    { name: "Vermont", code: "VT" },
    { name: "Washington", code: "WA" },
    { name: "Wisconsin", code: "WI" },
    { name: "WestVirginia", code: "WV" },
    { name: "Wyoming", code: "WY" },
  ];

  return (
    <>
      <div className="row  my-2 mx-auto">
        <div className="col-12">
          <span className="text-14 ">
            <b>Policy Information</b>
          </span>
        </div>
      </div>
      <div className="row smartscan-block mx-auto mt-2 text-12">
        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.insuredFirstNameError.length > 0 ? " validation-div" : ""}`}>
          <label>First Name</label>
          <span className="text-danger">*</span>
          <AtkTextField
            id="text"
            className="input-field"
            type="text"
            value={props.insuredFirstName}
            onChange={(e: any) => {
              props.onInsuredFirstNameChange(e);
            }}
            placeholder="Enter first name"
          />

          <span className="text-danger text-12">{props.insuredFirstNameError}</span>
        </div>
        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.insuredLastNameError.length > 0 ? " validation-div" : ""}`}>
          <label>Last Name</label>
          <span className="text-danger"> *</span>
          <AtkTextField
            id="text"
            className="input-field "
            type="text"
            value={props.insuredLastName}
            placeholder="Enter last name"
            onChange={(e: any) => {
              props.onInsuredLastNameChange(e);
            }}
          />
          <span className="text-danger text-12">{props.insuredLastNameError}</span>
        </div>
        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.policyNumberError.length > 0 ? " validation-div" : ""}`}>
          <label>Policy Number</label>
          <span className="text-danger">*</span>
          <AtkTextField
            id="text"
            className="input-field "
            value={props.policyNumber}
            placeholder="Enter policy number"
            type="text"
            onChange={(e: any) => {
              props.onPolicyNumberChange(e);
            }}
          />
          <span className="text-danger text-12">{props.policyNumberError}</span>
        </div>

        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.premiumAmountError.length > 0 ? " validation-div" : ""}`}>
          <label>Premium Amount ($)</label>
          <span className="text-danger ">*</span>
          <AtkTextField
            id="text"
            className="input-field "
            type="text"
            value={props.premiumAmount.length > 0 ? props.premiumAmount : ""}
            placeholder="Enter premium amount"
            onChange={(e: any) => {
              props.onPremiumChange(e);
            }}
          />
          <span className="text-danger text-12">{props.premiumAmountError}</span>

          {/* {props.premiumErrorMessage.length > 0 ? (
              <span className="text-danger small">
                {props.premiumErrorMessage[props.index]}
              </span>
            ) : null} */}
        </div>
        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.coverageCodeError.length > 0 ? " validation-div" : ""}`}>
          <label>Coverage Code</label>
          <span className="text-danger ">*</span>

          <AtkTextField
            id="text"
            className="input-field "
            type="text"
            value={props.lineOfBusiness}
            onChange={props.onCoverageCodeChange}
            placeholder="Enter coverage code"
          />
          <span className="text-danger text-12">{props.coverageCodeError}</span>
        </div>
        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.termError.length > 0 ? " validation-div" : ""}`}>
          <label>Term</label>
          <span className="text-danger ">*</span>

          <AtkTextField id="text" className="input-field " type="text" value={props.term} onChange={props.onTermChange} placeholder="Enter term" />
          <span className="text-danger text-12">{props.termError}</span>
        </div>
        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
          <label>Carrier Name</label>
          {/* <span className="text-danger"> *</span> */}
          <AtkTextField
            id="text"
            className="input-field "
            type="text"
            value={props.carrierName}
            placeholder="Enter carrier name"
            onChange={(e: any) => {
              props.onCarrierChanges(e);
            }}
          />
        </div>

        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.effectiveDateError.length > 0 ? " validation-div" : ""}`}>
          <label>Effective Date</label>
          <span className="text-danger ">*</span>

          <AtkTextField
            id="text"
            className="input-field "
            type="date"
            value={props.effectiveDate != undefined && props.effectiveDate != null ? moment(props.effectiveDate).format("YYYY-MM-DD") : ""}
            onChange={props.onEffectiveDateChange}
            placeholder="Select effective date"
          />
          <span className="text-danger text-12">{props.effectiveDateError}</span>
        </div>

        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
          <label>Quote Number</label>

          <AtkTextField
            id="text"
            className="input-field "
            type="text"
            value={props.quoteNumber}
            placeholder="Enter quote number"
            onChange={(e: any) => {
              props.onQuoteChange(e);
            }}
          />
        </div>
        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
          <label>Down Payment ($)</label>
          <AtkTextField
            id="text"
            placeholder="Enter down payment"
            className="input-field "
            type="text"
            value={props.downPayment != undefined ? props.downPayment : ""}
            onChange={(e: any) => {
              props.onDownPaymentChange(e);
            }}
          />
        </div>

        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
          <label>Financed Amount ($)</label>
          <AtkTextField
            id="text"
            className="input-field "
            type="text"
            value={props.financedAmount != undefined ? props.financedAmount : ""}
            placeholder="Enter financed amount"
            onChange={(e: any) => {
              props.onFinancedAmountChange(e);
            }}
          />
        </div>
        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
          <label>Effective APR</label>
          <AtkTextField
            id="text"
            className="input-field "
            value={props.effectiveAPR}
            placeholder="Enter effective apr"
            type="text"
            onChange={(e: any) => {
              props.onEffectiveAprChange(e);
            }}
          />
        </div>

        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
          <label>Other Taxes</label>
          <AtkTextField
            id="text"
            className="input-field "
            value={props.taxes != undefined ? props.taxes : 0}
            placeholder="Enter other taxes"
            type="number"
            onChange={(e: any) => {
              props.onOtherTaxesChange(e);
            }}
          />
        </div>

        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
          <label>Fees</label>
          <AtkTextField
            id="text"
            className="input-field "
            value={props.fees != undefined ? props.fees : 0}
            placeholder="Enter other fees"
            type="number"
            onChange={(e: any) => {
              props.onOtherFeesChange(e);
            }}
          />
        </div>

        {/* <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
          <label>Broker Fee</label>
          <AtkTextField
            id="text"
            className=""
            value={props.brokerFee}
            placeholder="Enter broker fee"
            type="text"
            onChange={(e: any) => {
              props.onBrokerFeeChange(e);
            }}
          />
        </div> */}
      </div>

      <div className="row  my-2 mx-auto ">
        <div className="col-12">
          <span className="text-14 ">
            <b>Address Information</b>
          </span>
        </div>
      </div>
      <div className="row smartscan-block mx-auto mt-2 text-12">
        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
          <label>Address Line 1</label>
          {/* <span className="text-danger"> *</span> */}
          <AtkTextField
            id="text"
            className="input-field "
            type="text"
            value={props.addressLine1}
            placeholder="Enter address line1"
            onChange={(e: any) => {
              props.onAddressLine1Change(e);
            }}
          />
        </div>
        <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
          <label>City</label>
          {/* <span className="text-danger"> *</span> */}
          <AtkTextField
            id="text"
            className="input-field "
            type="text"
            value={props.city}
            placeholder="Enter city"
            onChange={(e: any) => {
              props.onCityChange(e);
            }}
          />
        </div>
        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.stateCodeError.length > 0 ? " validation-div" : ""}`}>
          <span>State</span>
          <span className="text-danger"> *</span>
          <select
            style={{ width: "100%", height: "30px", fontSize:"12px" }}
             className="input-field form-control "
            value={props.state}
            onChange={(e: any) => {
              props.onStateChange(e);
            }}
          >
            <option value="" selected>
              Select an state
            </option>

            {Allstate.map((state) => (
              <option key={state.code} value={state.code}>
                {state.code + "-" + state.name}
              </option>
            ))}
          </select>
          <span className="text-danger text-12 btisErrorText">{props.stateCodeError}</span>
        </div>
        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.zipCodeError.length > 0 ? " validation-div" : ""}`}>
          <label>Zip Code</label>
          <span className="text-danger"> *</span>
          <AtkTextField
            id="text"
            className="input-field "
            type="text"
            value={props.zipCode}
            placeholder="Enter zip code"
            onChange={(e: any) => {
              props.onZipCodeChange(e);
            }}
          />
          <span className="text-danger text-12 ">{props.zipCodeError}</span>
        </div>
      </div>
    </>
  );
}
