import React from "react";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import { useEffect, useState, useRef } from "react";
import AtkButton from "../AtlaskitControls/AtkButton";
import CommonValues, { Logout } from "../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { Navigate, useNavigate } from "react-router-dom";

export default function PersonalDetails() {
  const [buisinessName, setBusinessName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [nationalProducerNumber, setNationalProducerNumber] = useState("");
  const [address, setAddress] = useState("");
  const [errorMessageFirstName, setErrorMessageFirstName] = useState("");
  const [errorMessageLastName, setErrorMessageLastName] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessageContactNumber, seterrorMessageContactNumber] = useState("");     
  const [errorMessageNPN, setErrorMessageNPN] = useState("");
  const [submitloading, setSubmitLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);

  const toast: any = useRef("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getUserDetailsById();
  }, []);
  const onBusinessNameChange = (e: any) => {
    setBusinessName(e.target.value);
  };
  const onFirstNameChange = (e: any) => {
    let inputValue = e.target.value.replace(/[^a-zA-Z ]/g, "");
    const limitedValue = inputValue.slice(0, 255);
    setFirstName(limitedValue);
  };
  const onLastNameChange = (e: any) => {
    let inputValue = e.target.value.replace(/[^a-zA-Z ]/g, "");
    const limitedValue = inputValue.slice(0, 255);
    setLastName(limitedValue);
  };
  const onEmailChange = (e: any) => {
    let limitedValue = e.target.value.slice(0, 255);
    setUserEmail(limitedValue);
  };
  const onContactNumberChange = (e: any) => {
    let phoneNumber = e.target.value.replace(/[^0-9]/g, "");
    let limitedValue = phoneNumber.slice(0, 10);
    setContactNumber(limitedValue);
  };
  const onNationalProducerNumberChange = (e: any) => {
    let limitedValue = e.target.value.slice(0, 6);
    setNationalProducerNumber(limitedValue);
  };
  const onAddressChange = (e: any) => {
    let limitedValue = e.target.value.slice(0, 500);
    setAddress(limitedValue);
  };
  const getUserDetailsById = () => {
    setErrorMessageFirstName("");
    setErrorMessageLastName("");
    setErrorMessageEmail("");
    seterrorMessageContactNumber("");
    setErrorMessageNPN("");

    setProfileLoading(true);

    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/userdetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setProfileLoading(false);
        setBusinessName(response.data.companyName);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setUserEmail(response.data.workEmail);
        setContactNumber(response.data.phoneNumber);
        setNationalProducerNumber(response.data.npn);
        setAddress(response.data.address);
      })
      .catch((error) => {
        setProfileLoading(false);

        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while loading the user details", life: 3000 });
          }
        }
      });
  };
  const checkPersonalDetailValidation = () => {
    let formIsValid = true;

    setErrorMessageFirstName("");
    setErrorMessageLastName("");
    setErrorMessageEmail("");
    seterrorMessageContactNumber("");
    setErrorMessageNPN("");

    if (firstName === "") {
      formIsValid = false;
      setErrorMessageFirstName("Please enter first name");
    } else {
      setErrorMessageFirstName("");
    }

    if (lastName === "") {
      formIsValid = false;
      setErrorMessageLastName("Please enter last name");
    } else {
      setErrorMessageLastName("");
    }

    if (userEmail === "") {
      formIsValid = false;
      setErrorMessageEmail("Please enter email");
    } else {
      setErrorMessageEmail("");
    }

    if (contactNumber !== "" && contactNumber.length != 10) {
      formIsValid = false;
      seterrorMessageContactNumber("Please enter a valid phone number");
    } else {
      seterrorMessageContactNumber("");
    }
    if (nationalProducerNumber !== null && nationalProducerNumber !== "") {
      if (nationalProducerNumber.length <= 5 && !nationalProducerNumber.match(/[0-9]{5}/)) {
        formIsValid = false;
        setErrorMessageNPN("Please enter a valid national producer number");
      } else if (nationalProducerNumber.length == 6 && !nationalProducerNumber.match(/[0-9]{5}[0-9a-zA-Z]{1}/)) {
        formIsValid = false;
        setErrorMessageNPN("Please enter a valid national producer number");
      } else {
        setErrorMessageNPN("");
      }
    } else {
      setErrorMessageNPN("");
    }
    return formIsValid;
  };
  const onSaveClick = () => {
    if (checkPersonalDetailValidation()) {
      setSubmitLoading(true);

      var token = CommonValues.GetToken();

      var data = JSON.stringify({
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber: contactNumber,
        Address: address,
        Email: userEmail,
        Npn: nationalProducerNumber,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/updatepersonaldetails`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          setSubmitLoading(false);
          toast.current.show({ severity: "success", detail: "Profile details updated successfully", life: 3000 });
        })
        .catch((error) => {
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout(navigate);
            } else {
              toast.current.show({ severity: "error", detail: "Unknown error while updating the personal details", life: 3000 });
            }
          }
        });
    }
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      onSaveClick();
    }
  };
  return (
    <>
      <Toast ref={toast} />

      <div className="row form-group text-start  mx-auto">
        <div className="col-12 profile-title">
          <span className="">Profile</span>
        </div>
      </div>
      {profileLoading ? (
        <AtkLoader />
      ) : (
        <>
          <div className="row form-group text-start mb-2  d-flex align-items-center">
            <label className="col-sm-4 col-form-label">Business Name</label>
            <div className="col-sm-8">
              <AtkTextField
                className="input-field"
                type="text"
                id="txtUserName"
                name="userName"
                isDisabled={true}
                onChange={onBusinessNameChange}
                value={buisinessName}
                placeholder=""
                onKeyDown={onEnterClick}
              />
            </div>
          </div>
          <div className="row form-group text-start mb-2  d-flex align-items-center">
            <label className="col-sm-4 col-form-label">
              First Name
              <span className="text-danger">*</span>
            </label>
            <div className={errorMessageFirstName.length > 0 ? "col-sm-8  validation-div" : "col-sm-8"}>
              <AtkTextField
                type="text"
                id="firstName"
                name="firstName"
                onChange={onFirstNameChange}
                value={firstName}
                className="input-field"
                placeholder="Enter first name"
                onKeyDown={onEnterClick}
              />
            </div>
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <span className="text-danger small">{errorMessageFirstName}</span>
            </div>
          </div>
          <div className="row form-group text-start mb-2  d-flex align-items-center">
            <label className="col-sm-4 col-form-label">
              Last Name
              <span className="text-danger">*</span>
            </label>
            <div className={errorMessageLastName.length > 0 ? "col-sm-8  validation-div" : "col-sm-8"}>
              <AtkTextField
                type="text"
                id="lastName"
                name="lastName"
                onChange={onLastNameChange}
                value={lastName}
                className="input-field"
                placeholder="Enter last name"
                onKeyDown={onEnterClick}
              />
            </div>
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <span className="text-danger small">{errorMessageLastName}</span>
            </div>
          </div>
          <div className="row form-group text-start mb-2  d-flex align-items-center">
            <label className="col-sm-4 col-form-label">
              Email<span className="text-danger">*</span>{" "}
            </label>
            <div className={errorMessageEmail.length > 0 ? "col-sm-8  validation-div" : "col-sm-8"}>
              <AtkTextField
                className="input-field"
                type="text"
                id="txtUserName"
                name="userEmail"
                onChange={onEmailChange}
                value={userEmail}
                placeholder="Enter email"
                isDisabled={true}
                onKeyDown={onEnterClick}
              />
            </div>
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <span className="text-danger small">{errorMessageEmail}</span>
            </div>
          </div>
          <div className="row form-group text-start mb-2  d-flex align-items-center">
            <label className="col-sm-4 col-form-label">Contact Number</label>
            <div className={errorMessageContactNumber.length > 0 ? "col-sm-8  validation-div" : "col-sm-8"}>
              <AtkTextField
                className="input-field"
                type="text"
                id="txtUserName"
                name="contactNumber"
                onChange={onContactNumberChange}
                value={contactNumber}
                placeholder="Enter contact number"
                onKeyDown={onEnterClick}
              />
            </div>
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <span className="text-danger small">{errorMessageContactNumber}</span>
            </div>
          </div>
          <div className="row form-group text-start mb-2  d-flex align-items-center">
            <label className="col-sm-4 col-form-label">National Producer Number</label>
            <div className={errorMessageNPN.length > 0 ? "col-sm-8  validation-div" : "col-sm-8"}>
              <AtkTextField
                className="input-field"
                type="text"
                id="txtnpn"
                name="npn"
                onChange={onNationalProducerNumberChange}
                value={nationalProducerNumber}
                placeholder="Enter national producer number"
                onKeyDown={onEnterClick}
              />
            </div>
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <span className="text-danger small">{errorMessageNPN}</span>
            </div>
          </div>

          <div className="row form-group text-start mb-2  d-flex align-items-center">
            <label className="col-sm-4 col-form-label">Address</label>
            <div className="col-sm-8">
              <textarea
                className="profile-text-area w-100"
                id="txtAddress"
                name="address"
                onChange={onAddressChange}
                value={address}
                placeholder="Enter address"
                rows={8}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12 text-end">
              {submitloading ? <AtkLoader /> : <AtkButton label="Save" onClick={onSaveClick} className="pf-primary-btn w-auto me-1   my-1 " />}
            </div>
          </div>
        </>
      )}
    </>
  );
}
