import React, { FC, useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import SectionMessage from "@atlaskit/section-message";
import AtkButton from "../AtlaskitControls/AtkButton";
import MoreBtn from "../../src/assets/images/continue.png";
import CommonValues from "../common/utils";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import axios from "axios";
import StarIcon from "@atlaskit/icon/glyph/star";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { Logout } from "../common/utils";
import { Navigate, useNavigate } from "react-router-dom";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";
export default function Ams360Setting(props: any) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [userName, setUserName] = useState("");
  const [ams360Password, setAms360Password] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [successMessageVertafore, setSuccessMessageVertafore] = useState("");
  const [errorMessageVertafore, setErrorMessageVertafore] = useState("");
  const [agencyNameError, setAgencyNameError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [invalidCredMessage, setInvalidCredMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [heightFull, setHeightFull] = useState(false);
  const navigate = useNavigate();
  const toast: any = useRef("");

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    setTimeout(() => {
      getVertaforeSettings(false);
    }, 10);
  }, []);

  const onAms360AgencyNameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setAgencyName(e.target.value);
  };
  const onAms360PasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setAms360Password(e.target.value);
  };
  const onAms360UserNameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setUserName(e.target.value);
  };
  const onAms360EmployeeCodeChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setEmployeeCode(e.target.value);
  };
  const getVertaforeSettings = (val: any) => {
    setAgencyNameError("");
    setUserNameError("");
    setPasswordError("");
    setAgencyName("");
    setUserName("");
    setAms360Password("");
    setEmployeeCode("");
    setLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/vertaforesettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setLoading(false);
        if (val) {
          if (Object.keys(response.data).length > 0) {
            getVertaforeAgencyDetails();
          } else {
            toast.current.show({ severity: "info", detail: "Please enter ams360 AMS credentials", life: 3000 });
          }
        }
        setAgencyName(response.data.agencyName);
        setUserName(response.data.userName);
        setAms360Password(response.data.password);
        setEmployeeCode(response.data.employeeCode);
        if (
          response.data.agencyName !== null &&
          response.data.agencyName !== "" &&
          response.data.agencyName !== undefined &&
          response.data.userName !== null &&
          response.data.userName !== "" &&
          response.data.userName !== undefined
        ) {
          setAms360Password("*********");
        }

        setSuccessMessageVertafore("");
        setErrorMessageVertafore("");
        if (response.data.agencyName == "" || response.data.agencyName == null || response.data.userName == "" || response.data.userName == null) {
          localStorage.setItem("VertaforeSetting", "true");
        } else {
          localStorage.setItem("VertaforeSetting", "false");
        }
      })
      .catch((error: any) => {
        setLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the AMS360 settings";
            toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
          }
        }
      });
  };

  const onSaveClicked = () => {
    if (isValid()) {
      setLoading(true);
      const token = CommonValues.GetToken();
      var data = {
        AgencyName: agencyName,
        UserName: userName,
        Password: ams360Password,
        EmployeeCode: employeeCode,
      };
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/vertaforesettings`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "Application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setLoading(false);
          toast.current.show({ severity: "success", detail: "AMS360 settings updated successfully", life: 3000 });

          setInvalidCredMessage("");
          setTimeout(() => {
            getVertaforeSettings(false);
          }, 500);
          getVertaforeAgencyDetails();
        })
        .catch((error: any) => {
          setLoading(false);
          setInvalidCredMessage("");
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status === 401) {
              CommonValues.Logout(navigate);
            } else if (error.response.status === 404) {
              errorMessage = "Invalid ams360 credentials!";
              toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
            } else {
              errorMessage = "Unknown error while saving the AMS360 settings";
              toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
            }
          }
        });
    }
  };
  const getVertaforeAgencyDetails = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_VERTAFOREAPI}/api/Vertafore/agencyinfo`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        let agencyData = {
          agencyName: response.data.AgencyInfo.AgencyName,
          agencyAddress1: response.data.AgencyInfo.Address,
          agencyCity: response.data.AgencyInfo.City,
          agencyState: response.data.AgencyInfo.State,
          agencyZip: response.data.AgencyInfo.ZipCode,
          agencyCountry: response.data.country,
          agencyPhone: response.data.AgencyInfo.BusinessAreaCode + response.data.AgencyInfo.BusinessPhone,
        };
        onSaveAmsAgencySetting(agencyData);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while loading the agency details", life: 3000 });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while loading the agency details", life: 3000 });
        }
      });
  };
  const onSaveAmsAgencySetting = (agencyData: any) => {
    const token = CommonValues.GetToken();
    var data = {
      CompanyId: agencyData.CompanyId,
      AgencyName: agencyData.agencyName,
      Country: agencyData.agencyCountry,
      Address1: agencyData.agencyAddress1,
      Address2: agencyData.agencyAddress2,
      State: agencyData.agencyState,
      Zip: agencyData.agencyZip,
      City: agencyData.agencyCity,
      Phone: agencyData.agencyPhone,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setLoading(false);
        props.onPrimaryAMSSelected();
        props.getCommonSettings();
      })
      .catch((error: any) => {
        setLoading(false);
        setInvalidCredMessage("");

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 404) {
            toast.current.show({ severity: "error", detail: "Invalid ams360 credentials!", life: 3000 });
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while saving the AMS360 settings", life: 3000 });
          }
        }
      });
    // }
  };
  const isValid = () => {
    let valid = true;
    setAgencyNameError("");
    setUserNameError("");
    setPasswordError("");
    if (agencyName == "" || agencyName == null) {
      valid = false;
      setAgencyNameError("Please enter agency name");
    }
    if (userName == "" || userName == null) {
      valid = false;
      setUserNameError("Please enter username");
    }
    if (ams360Password == "" || ams360Password == null) {
      valid = false;
      setPasswordError("Please enter password");
    }

    return valid;
  };
  const onMoreButtonClick = () => {
    setHeightFull(!heightFull);
  };
  const onShowPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      onSaveClicked();
    }
  };
  const onAms360Select = () => {
    getVertaforeSettings(true);
  };
  return (
    <>
      <Toast ref={toast} />

      <div className="common-setting-block height-large">
        <div className="row ams-header mx-auto">
          <div className="col-6">
            <h4 className="text-20 fw-normal">AMS360</h4>
          </div>
          <div className="col-6 text-end">
            <span className="ams-notselected-icon">
              {props.vertaforeSelected ? (
                <span
                  onClick={() => {
                    onAms360Select();
                  }}
                  className="bookmark-history-icon-after-save"
                >
                  <StarFilledIcon label="Bookmarked" />
                </span>
              ) : (
                <span
                  onClick={() => {
                    onAms360Select();
                  }}
                  className="bookmark-history-icon"
                >
                  <StarIcon label="Bookmark" />
                </span>
              )}
            </span>
          </div>
        </div>
        {loading ? (
          <>
            <div className="d-flex justify-content-center align-items-center mt-2">
              <AtkLoader></AtkLoader>
            </div>
            <div className="d-flex justify-content-center mt-2">
              {invalidCredMessage.length > 0 ? <AtkMessage appearance="warning" messageText={invalidCredMessage} /> : null}
            </div>
          </>
        ) : (
          <>
            <div className="row mx-auto text-12 p-1 ">
              <div className={agencyNameError.length > 0 ? "form-group validation-div mt-2" : "form-group mt-2"}>
                <label className="form-label">
                  Agency Name<span className="text-danger">*</span>
                </label>
                <AtkTextField
                  type="text"
                  id="agencyName"
                  name="agencyName"
                  className="input-field"
                  onChange={onAms360AgencyNameChange}
                  value={agencyName}
                  placeholder="Enter agency name"
                />
                <span className="text-danger small">{agencyNameError}</span>
              </div>
              <div className={userNameError.length > 0 ? "form-group validation-div mt-2" : "form-group mt-2"}>
                <label className="form-label">
                  Username<span className="text-danger">*</span>
                </label>
                <AtkTextField
                  type="text"
                  id="userName"
                  name="userName"
                  className="input-field"
                  onChange={onAms360UserNameChange}
                  value={userName}
                  placeholder="Enter username"
                />
                <span className="text-danger small">{userNameError}</span>
              </div>
              <div className={passwordError.length > 0 ? "form-group position-relative validation-div mt-2" : "form-group position-relative mt-2"}>
                <label className="form-label">
                  Password<span className="text-danger">*</span>
                </label>
                <AtkTextField
                  type={passwordType}
                  id="ams360Password"
                  name="ams360Password"
                  className="input-field"
                  onChange={onAms360PasswordChange}
                  value={ams360Password}
                  placeholder="Enter password"
                  autoComplete="new-password"
                  onKeyDown={onEnterClick}
                />
                <span className="hawksoft-settings-eye-icon" onClick={onShowPassword}>
                  {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                </span>
              </div>
              {passwordError.length > 0 ? (
                <>
                  <div className="">
                    <span className="text-danger text-12">{passwordError}</span>
                  </div>
                </>
              ) : null}
            </div>

            <div className="row mx-auto">
              <div className="form-group mt-2">
                <label className="form-label">Employee Code</label>

                <AtkTextField
                  type="text"
                  id="employeeCode"
                  name="employeeCode"
                  onChange={onAms360EmployeeCodeChange}
                  value={employeeCode}
                  placeholder="Enter employee code"
                  className="input-field"
                  onKeyDown={onEnterClick}
                />
              </div>
              <div className="col-12 text-end">
                <AtkButton label="Save" onClick={onSaveClicked} className="pf-secondary-btn w-auto my-3" />
              </div>
            </div>

            <div className="col-md-12 my-2">
              <SectionMessage appearance="warning">
                <p>
                  <a className="small" href="https://youtu.be/2sb5Pj9wDLY" target="_blank">
                    Please make sure data sharing is enabled in AMS360
                  </a>
                </p>
              </SectionMessage>
            </div>
            <div className="col-md-12 mt-2">
              {errorMessageVertafore.length > 0 ? <AtkMessage appearance="error" messageText={errorMessageVertafore} /> : null}

              {successMessageVertafore != null && successMessageVertafore != "" ? (
                <AtkMessage appearance="success" messageText={successMessageVertafore} />
              ) : null}
            </div>

            <div className="mb-2">
              <span className="text-danger">{errorMessage}</span>
            </div>
          </>
        )}
      </div>
    </>
  );
}
