import React, { FC, useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import CommonValues from "../common/utils";
import AtkButton from "../AtlaskitControls/AtkButton";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      setShowLogin(true);
    }
  }, []);
  const onLoginPage = () => {
    navigate("/");
  };
  const onLandingPage = () => {
    navigate("/landingpage");
  };
  return (
    <div className="nowcerts-sso-page">
      <Header />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center">
        <div className="login-box d-flex align-items-center justify-content-center" style={{ minHeight: "30vh" }}>
          <div className="row mx-auto ">
            <div className="col-12   text-center ">
              <div className="page-not-found-heading">
                <span className="text-22">404</span>
                <span className="text-22 d-block mb-2">Page not found!</span>
                <span className="text-16">We're sorry, the page you requested could not be found .</span>
              </div>

              <div className="text-justify  container">
                {showLogin ? (
                  <>
                    <div className=" text-16 mb-2">Please go back to the login page</div>
                    <div>
                      <AtkButton label="Login" onClick={onLoginPage} className="pf-primary-btn w-auto" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className=" text-16 mb-2">Please go back to the landing page</div>
                    <div>
                      <AtkButton label="Home" onClick={onLandingPage} className="pf-primary-btn w-auto" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
