import React from 'react'
import SectionMessage from '@atlaskit/section-message';

export default function AtkMessage(props:any) {
  return (
    <SectionMessage appearance={props.appearance} >
    <p className="text-12">{props.messageText}</p>
  </SectionMessage>
  )
}
