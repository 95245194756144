import React from "react";

import ProgressBar from "@atlaskit/progress-bar";

const AtkProgressBar = (props: any) => {
  return (
    <div>
      <div className="text-center w-100">
        <span className="mb-2">{props.progressbarlabel}</span>
      </div>
      <div>
        <ProgressBar ariaLabel="Loading issues" isIndeterminate />
      </div>
    </div>
  );
};

export default AtkProgressBar;
