import React, { FC, useEffect, useState, useRef } from "react";
import axios from "axios";
import CommonValues, { Logout } from "../../../common/utils";
import { Toast } from "primereact/toast";
import AtkTextField from "../../../AtlaskitControls/AtkTextField";
import AtkButton from "../../../AtlaskitControls/AtkButton";
import AtkLoader from "../../../AtlaskitControls/AtkLoader";
import AtkMessage from "../../../AtlaskitControls/AtkMessage";
import { Navigate, useNavigate } from "react-router-dom";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";

export default function USPFSettings(props: any) {
  const [loading, setLoading] = useState(false);
  const [usPfLoading, setUSPfLoading] = useState(false);
  const [deleteUSPfLoading, setDeleteUSPfLoading] = useState(false);
  const [errorMessageUsername, setErrorMessageUsername] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageSearchCode, setErrorMessageSearchCode] = useState("");
  const [usPassword, setUsPassword] = useState("");
  const [searchCode, setSearchCode] = useState("");
  const [usUsername, setUsUsername] = useState("");
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [preferredVendorLoading, setPreferredVendorLoading] = useState(false);
  const hostname = window.location.hostname;
  const [showIGCredsMsg, setShowIGCredsMsg] = useState(false);
  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getUSPfCreds();
  }, []);
  const getUSPfCreds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/uspfcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          setUsUsername("");
          setSearchCode("");
          setUsUsername("");
          setShowIGCredsMsg(true);
        } else {
          setShowIGCredsMsg(false);
          setUsUsername(response.data.username);
          setSearchCode(response.data.searchCode);
          if (response.data.username !== null && response.data.username !== "" && response.data.password === "") {
            setUsPassword("*********");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the USPF Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the USPF Setting details";
        }
        toast.current.show({ severity: "error", detail: { errorMessage } });

        setLoading(false);
      });
  };
  const onUsernameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setUsUsername(e.target.value);
    setSearchCode(e.target.value);
  };

  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setUsPassword(e.target.value);
  };
  const onSearchCodeChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setSearchCode(e.target.value);
  };

  const onShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const onKeyUpdateUSPfClick = (e: any) => {
    if (e.keyCode == 13) {
      onUpdateUSPfClick();
    }
  };
  const uspfCheckValidation = () => {
    let formIsValid = true;
    setErrorMessageUsername("");
    setErrorMessagePassword("");
    setErrorMessageSearchCode("");

    if (usUsername == "" || usUsername == null) {
      formIsValid = false;
      setErrorMessageUsername("Please enter user name");
    }
    // if (usPassword == "" || usPassword == null) {
    //   formIsValid = false;
    //   setErrorMessagePassword("Please enter password");
    // }

    return formIsValid;
  };
  const onUpdateUSPfClick = () => {
    if (uspfCheckValidation()) {
      setUSPfLoading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        Username: usUsername,
        Password: usPassword,
        SearchCode: searchCode,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/uspfcreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setUSPfLoading(false);
          toast.current.show({ severity: "success", detail: "USPF Settings updated successfully" });

          getUSPfCreds();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              errorMessage = "It look likes you are not registered with USPF, Please register first.";
            } else {
              errorMessage = "Unknown error while updating the USPF Settings";
            }
          } else {
            errorMessage = "Unknown error while updating the USPF Settings";
          }
          toast.current.show({ severity: "error", detail: { errorMessage } });

          setUSPfLoading(false);
        });
    }
  };
  const onDeleteUSPfClick = () => {
    setErrorMessageUsername("");
    setErrorMessagePassword("");
    setDeleteUSPfLoading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/uspfcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setDeleteUSPfLoading(false);
        toast.current.show({ severity: "success", detail: "USPF Settings deleted successfully" });

        getUSPfCreds();
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while deleting the USPF Settings";
          }
        } else {
          errorMessage = "Unknown error while deleting the USPF Settings";
        }
        toast.current.show({ severity: "error", detail: { errorMessage } });

        setDeleteUSPfLoading(false);
      });
  };
  const insertPreferredVendor = (vendorId: any, isChecked: any) => {
    setPreferredVendorLoading(true);
    let token = CommonValues.GetToken();
    var data = JSON.stringify({
      ProviderId: vendorId,
      IsChecked: !isChecked,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/preferredvendor`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        // toast.current.show({ severity: "success", detail: "Capital Settings updated successfully", life: 3000 });
        props.getVendorListFunction();
        setPreferredVendorLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setPreferredVendorLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while updating the preferred vendor" });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while updating the preferred vendor" });
        }
      });
  };
  return (
    <>
      <div className="row mx-auto">
        <Toast ref={toast} />
        <div className="col-12">
          <div className="pf-setting-col">
            <h4 className="pf-setting-title">
              USPF Financing
              {hostname === process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2 ? (
                <>
                  {preferredVendorLoading ? (
                    <AtkLoader></AtkLoader>
                  ) : (
                    <input
                      type="checkbox"
                      value=""
                      checked={props.usPremiumFinanceVendor}
                      onChange={() => insertPreferredVendor(7, props.usPremiumFinanceVendor)}
                      className="show-cursor bundle-checkbox "
                    />
                  )}
                </>
              ) : null}
            </h4>

            <div
              className={
                loading
                  ? "row pf-setting-inputs  mx-auto div-blur-loading d-flex align-items-center"
                  : "row pf-setting-inputs  mx-auto d-flex align-items-center "
              }
            >
              <div className="col-4 d-flex align-items-center mt-2">
                <label className="form-label">
                  Login
                  <span className="text-danger">*</span>
                </label>
              </div>
              <div className={errorMessageUsername.length > 0 ? "col-8 mt-2 validation-div" : "col-8 mt-2 "}>
                <AtkTextField
                  type="text"
                  id="username"
                  name="usUsername"
                  className="input-field"
                  onChange={onUsernameChange}
                  value={usUsername}
                  onKeyDown={onKeyUpdateUSPfClick}
                  placeholder="Enter login"
                />
                <span className="text-danger text-12">{errorMessageUsername}</span>
              </div>

              <div className="col-4 d-flex align-items-center mt-2">
                <label className="form-label">
                  Password
                  {/* <span className="text-danger">*</span> */}
                </label>
              </div>
              <div className={errorMessagePassword.length > 0 ? "col-8 mt-2 validation-div position-relative" : "col-8 mt-2 position-relative"}>
                <AtkTextField
                  type={passwordType}
                  className="input-field"
                  id="headerKey"
                  name="usPassword"
                  onChange={onPasswordChange}
                  value={usPassword}
                  onKeyDown={onKeyUpdateUSPfClick}
                  autoComplete="new-password"
                  placeholder="Enter password"
                />
                <span className="eye-icon" onClick={onShowPassword}>
                  {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                </span>
              </div>
              {/* {errorMessagePassword.length > 0 ? (
                <>
                  <div className="col-4  "></div>
                  <div className=" col-8 ">
                    <span className="text-danger text-12">{errorMessagePassword}</span>
                  </div>
                </>
              ) : null} */}
              <div className="col-4 d-flex align-items-center mt-2 mb-2">
                <label className="form-label">Search Code</label>
              </div>
              <div className="col-8 mt-2 mb-2">
                <AtkTextField
                  type="text"
                  id="ownerSearchCode"
                  className="input-field"
                  name="ownerSearchCode"
                  onChange={onSearchCodeChange}
                  value={searchCode}
                  onKeyDown={onKeyUpdateUSPfClick}
                  placeholder="Enter search code"
                />
                <span className="text-danger text-12">{errorMessageSearchCode}</span>
              </div>
              <div className="col-md-12 mb-2">
                {showIGCredsMsg ? <AtkMessage appearance="information" messageText="Using InsuranceGIG Credentials" /> : null}
              </div>
              <div className="row mx-auto  setting-btn-position ">
                <div className="col-4 mt-3 mb-1"></div>
                <div className="col-4 mt-3 mb-1">
                  {deleteUSPfLoading ? (
                    <AtkLoader></AtkLoader>
                  ) : (
                    <AtkButton label="Reset" onClick={onDeleteUSPfClick} className="pf-primary-btn w-100" />
                  )}
                </div>
                <div className="col-4 mt-3 mb-1">
                  {usPfLoading ? <AtkLoader></AtkLoader> : <AtkButton label="Save" onClick={onUpdateUSPfClick} className="pf-primary-btn w-100" />}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}