import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import CommonValues from "../common/utils";

export default function VehicelHistory() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [vehicleHistoryLoading, setVehicleHistoryLoading] = useState(true);
  const [vehicleHistoryData, setVehicleHistoryData] = useState<any>([]);

  useEffect(() => {
    const token = CommonValues.GetToken();

    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getVehicleHistory();
  }, []);

  const getVehicleHistory = () => {
    setVehicleHistoryLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/vehicle/getvehiclehistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setVehicleHistoryData(response.data);
        setVehicleHistoryLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting vehicle history",
              life: 3000,
            });
          }
        }
        setVehicleHistoryLoading(false);
      });
  };

  return (
    <>
      <Toast ref={toast} />
      {vehicleHistoryLoading ? (
        <div className="d-flex justify-content-center align-items-center mt-2">
          <AtkLoader />{" "}
        </div>
      ) : (
        <div className="row">
          <div className="col-12  history-table mt-3">
            <DataTable
              value={vehicleHistoryData}
              paginator
              paginatorTemplate="PrevPageLink PageLinks NextPageLink"
              rows={10}
              dataKey="id"
            >
              <Column
                header="Insured Name"
                field="insuredName"
                sortable
              ></Column>
              <Column
                header="Created"
                field="created"
                body={(rowData: any) => {
                  if (rowData.created != null) {
                    return moment
                      .utc(rowData.created, "YYYY-MM-DD")
                      .format("DD-MM-YYYY");
                  }
                }}
              ></Column>
              <Column
                header="Status"
                field="status"
                sortable
              ></Column>
              <Column
                header="Update In AMS"
                field="updatedInAMS"
                sortable
              ></Column>
              <Column
                header="API Cost"
                field="apiCost"
                body={(rowData: any) => (
                  <span>
                    $ {rowData.apiCost == null ? "0" : rowData.apiCost / 100}
                  </span>
                )}
              ></Column>
            </DataTable>
          </div>
        </div>
      )}
    </>
  );
}
