import React from 'react'

export default function AtkIcon(props:any) {
  return (
    <div>
    <img src={props.icon} height="18px" width="18px" />
  </div>
  )
}

