import React from 'react'
import billingLogo from "../assets/images/BillingHeader.svg";


export default function BillingMiddleHeader() {
  return (
    <div className="row middle-header-row">
    <div className="col-12">
     
      <div className="middle-header-col active ms-2">
        <img src={billingLogo} className="" />
        <span className="middle-header-title">Billing</span>
      </div>
    </div>{" "}
  
  </div>  )
}
