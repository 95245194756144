import { DataTable } from "primereact/datatable";
import moment from "moment";
import axios from "axios";
import CommonValues from "../../common/utils";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "primereact/column";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import ViewDetailsIcon from "../../assets/images/ViewDetailsIcon.png";
import PFOcrScan from "./PFSOcrScan";
import AtkButton from "../../AtlaskitControls/AtkButton";


export default function PFScanHistory(props: any) {
    const navigate = useNavigate();
    const toast: any = useRef("");
    const [pfScanHistoryData, setPFScanHistoryData] = useState<any>([]);
    const [historyLoading, setHistoryLoading] = useState(false)
    const [actionLoading, setActionLoading] = useState(false);
    const [submissionId, setSubmissionId] = useState();
    const [isSubmissionIdFromHistory, setIsSubmissionIdFromHistory] = useState(false);

    useEffect(() => {
        const token = CommonValues.GetToken();
        if (token == "" || token == null) {
            CommonValues.Logout(navigate);
        }
        getPFScanHistory();
    }, []);

    const getPFScanHistory = () => {
        setHistoryLoading(true);
        const token = CommonValues.GetToken();
        const config = {
            method: "get",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/pfscanhistory`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response: any) => {
                setPFScanHistoryData(response.data);
                setHistoryLoading(false);
            })
            .catch((error: any) => {
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while getting PFScan history",
                            life: 3000,
                        });
                    }
                }
                setHistoryLoading(false);
            });
    };

    const preview = (rowData: any) => {
        if (rowData.status == "Success") {
            return (
                <>
                    {actionLoading ? (
                        <div className="d-flex">
                            <AtkLoader />{" "}
                        </div>
                    ) : (
                        <span className="coi-download-icon">
                            <img
                                alt="View Details"
                                src={ViewDetailsIcon}
                                height="20"
                                width="20"
                                className="me-2 show-cursor"
                                title="View Details"
                                onClick={() => viewSmartScan(rowData.submissionId)}
                            />
                        </span>
                    )}
                </>
            );
        } else {
            return <span className=" text-12 quote-not-span">Not Available</span>;
        }
    };

    const viewSmartScan = (submissionId: any) => {
        setSubmissionId(submissionId);
        setIsSubmissionIdFromHistory(true);
    };
    const OnBack = () => {
        setIsSubmissionIdFromHistory(false);
    };

    return (
        <>

            {isSubmissionIdFromHistory ? (
                <>
                    <div className="col-12">
                        <AtkButton
                            label="Back"
                            onClick={OnBack}
                            className="pf-secondary-btn w-auto mx-4"
                        />
                    </div>
                    <div className="col-12">
                        <PFOcrScan
                            submissionIdFromHistory={submissionId}
                            isSubmissionIdFromHistory={isSubmissionIdFromHistory}
                        />
                    </div>
                </>
            ) : (
                <>
                    {historyLoading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <AtkLoader />{" "}
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-12  history-table mt-5">
                                <DataTable
                                    value={pfScanHistoryData}
                                    paginator
                                    paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                    rows={10}
                                >
                                    <Column
                                        header="Created Date"
                                        field="created"
                                        body={(rowData: any) => {
                                            if (rowData.created != null || rowData.created !== "") {
                                                return moment
                                                    .utc(rowData.created, "YYYY-MM-DD")
                                                    .format("DD-MM-YYYY");
                                            }
                                        }}
                                    ></Column>
                                    <Column header="SubmissionId" field="submissionId" sortable></Column>
                                    <Column header="File Name" field="fileName" sortable></Column>
                                    <Column
                                        header="Update In AMS"
                                        field="updateInAMS"
                                        sortable
                                    ></Column>
                                    <Column header="Status" field="status"></Column>
                                </DataTable>
                            </div>
                        </div>
                    )
                    }
                </>
            )
            }
        </>
    )
}