import React, { FC, useEffect, useState, useRef } from "react";
import SectionMessage from "@atlaskit/section-message";
import axios from "axios";
import CommonValues from "../common/utils";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";

export default function SkipThisStep(props:any) {
    const toast: any = useRef("");
    const [skipButtonLoading, setSkipButtonLoading] = useState(false);

  return (
    <>
      <Toast ref={toast} />

      <SectionMessage appearance="warning">
        <p>
          <span className=" d-block text-14 text-dark">Are you sure you want to skip this step of setup wizard?</span>
        </p>
        <div className="text-end mt-2">
          {skipButtonLoading ? <AtkLoader /> : <AtkButton label="OK" onClick={props.onSkipCurrentStepButtonClick} className="pf-secondary-btn w-auto me-2" />}
          <AtkButton label="Cancel" onClick={props.onCancelButtonClick} className="pf-primary-btn w-auto" />
        </div>
      </SectionMessage>
    </>
  )
}
