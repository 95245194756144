import React, { FC, useEffect, useState, useRef } from "react";
import CommonValues, { Logout } from "../common/utils";
import axios from "axios";
import { InputSwitch } from "primereact/inputswitch";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

export default function AddApps() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [loading, setLoading] = useState(false);
  const [widgetList, setWidgetList] = useState<any[]>([]);
  const [userWidgetList, setUserWidgetList] = useState<any[]>([]);
  const [updateWidgetList, setUpdateWidgetList] = useState<any[]>([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [widgetServiceId, setWidgetServiceId] = useState("");

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    setLoading(true);
    getAllApps();
  }, []);

  const getAllApps = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/widgetlist`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    axios(config)
      .then((response) => {
        setWidgetList(response.data);
        getAppsByUserId(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while getting the widget list";
          }
        }
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
      });
  };

  const getAppsByUserId = (wholeWidgetList: any[]) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/userwidgetlist`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setLoading(false);
        setUserWidgetList(response.data);
        const widgetIds = response.data.map((item: any) => item.widgetId);
        const updatedWidgetList = wholeWidgetList.map((widget: any) => {
          const isAssigned = widgetIds.includes(widget.widgetId);
          return {
            ...widget,
            isActive: isAssigned,
          };
        });
        setWidgetList(updatedWidgetList);
        const updatedList = updatedWidgetList.filter((item) => item.isActive);
        setUpdateWidgetList(updatedList);
        setUpdateLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setUpdateLoading(false);
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while getting the widget list by user id";
          }
          toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        }
      });
  };

  const onUpdateClick = (widget: any) => {
    var token = CommonValues.GetToken();
    let data = updateWidgetList;
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/userwidgetlist?ServiceId=${
        widget.serviceId
      }&IsVisibleOnChrLandingPage=${!widget.isActive}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    setUpdateLoading(true);
    axios(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Widgets updated successfully", life: 3000 });
        getAppsByUserId(widgetList);
      })
      .catch((error) => {
        setUpdateLoading(false);
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while getting the widget list by user id";
            toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
          }
        }
      });
  };
  const onIsActiveChange = (widget: any, e: any) => {
    setWidgetServiceId(widget.serviceId);
    onUpdateClick(widget);
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="row mx-auto">
        {loading ? (
          <div className="d-flex w-100 justify-content-center align-items-center add-apps-loader">
            <AtkLoader></AtkLoader>
          </div>
        ) : (
          <>
            <div className="col-12 feedback-header">
              <h4>Add Apps</h4>

              <div className=" mt-3 mb-2">
                <div className="row mx-auto text-12 ">
                  <div className="col-2">Icon</div>
                  <div className="col-7">Widget Name</div>
                  <div className="col-3">Installed</div>
                </div>
              </div>
            </div>

            {widgetList.map((widget: any, index: number) => (
              <div className={`row mx-auto text-12 ${widgetList.length == index + 1 ? " add-apps-div" : "add-apps-border add-apps-div"}  `} key={widget.widgetId}>
                <div className="col-2 ">
                  <span>
                    {" "}
                    <img src={widget.iconUrl} width={25} height={25} />
                  </span>
                </div>
                <div className="col-7">
                  <span>{widget.name}</span>
                </div>
                <div className="col-3">
                  {updateLoading && widget.serviceId == widgetServiceId ? (
                    <div className="my-1">
                      <AtkLoader></AtkLoader>
                    </div>
                  ) : (
                    <span>
                      <InputSwitch className="" checked={widget.isActive} onChange={(e) => onIsActiveChange(widget, e)} />{" "}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}
