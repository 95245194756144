import React, { ReactNode } from 'react';
import TextField from '@atlaskit/textfield';
import '@atlaskit/css-reset';

interface AtkTextFieldProps {
  minimum?: string | number | undefined;
  type: string;
  id: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  ref?: React.RefObject<HTMLInputElement>;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  className? :string;
  elemAfterInput?: ReactNode;
  autoComplete? :string;
  max?:string;
}

export default function AtkTextField(props: AtkTextFieldProps) {
  return (
    <TextField
      type={props.type}
      id={props.id}
      name={props.name}
      onChange={props.onChange}
      value={props.value}
      onKeyDown={props.onKeyDown}
      placeholder={props.placeholder}
      min ={props.minimum}
      className={props.className}
      elemAfterInput={props.elemAfterInput}
      autoComplete={props.autoComplete}
      isDisabled={props.isDisabled}
      max={props.max}
      />
  );
}
