import React, { FC, useEffect, useState, useRef } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import BillingSidebar from "./BillingSidebar";
import { Toast } from "primereact/toast";
import BillingMiddleHeader from "./BillingMiddleHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CommonValues, { Logout } from "../common/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import SectionMessage from "@atlaskit/section-message";
import AtkButton from "../AtlaskitControls/AtkButton";
import { OverlayPanel } from "primereact/overlaypanel";

export default function MySubscription() {
  const toast: any = useRef("");
  const [servicesList, setServicesList] = useState([]);
  const [servicesListLoading, setServicesListLoading] = useState(false);
  const [isRabbitSignSubscribed, setIsRabbitSignSubscribed] = useState(false);
  const [rabbitSignLoading, setRabbitSignLoading] = useState(false);
  const [rabbitSignSubscribeLoading, setRabbitSignSubscribeLoading] = useState(false);
  const navigate = useNavigate();
  const subscribeRef = useRef<OverlayPanel>(null);

  useEffect(() => {
    getServicesPerCallList();
    getRabbitSignSubscription();
  }, []);
  const getServicesPerCallList = () => {
    var token = CommonValues.GetToken();
    setServicesListLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/Billing/services-percall`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setServicesList(response.data.apiBilling);
        setServicesListLoading(false);
      })
      .catch((error: any) => {
        setServicesListLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting services list",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting services list",
            life: 3000,
          });
        }
      });
  };
  const getRabbitSignSubscription = () => {
    var token = CommonValues.GetToken();
    setRabbitSignLoading(true);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/Billing/subscription`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setIsRabbitSignSubscribed(response.data.subscriptionDetails.isRabbitSignSubscribed);
        setRabbitSignLoading(false);
      })
      .catch((error: any) => {
        setRabbitSignLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting rabbitsign subscription",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting rabbitsign subscription",
            life: 3000,
          });
        }
      });
  };
  const isAvailableTemplate = () => {
    return (
      <div className="round">
        <input type="checkbox" checked id="checkbox" />
        <label></label>
      </div>
    );
  };
  const costTemplate = (rowData: any) => {
    return <span>{rowData.costInCent / 100}</span>;
  };
  const onRabbitSignSubscribedClicked = () => {
    handleSkip();
    setIsRabbitSignSubscribed(!isRabbitSignSubscribed);
    var token = CommonValues.GetToken();
    setRabbitSignSubscribeLoading(true);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/Billing/rabbitsign-subscribe?subscribe=${!isRabbitSignSubscribed}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setRabbitSignSubscribeLoading(false);

        if (response.data.inserted > 0) {
          toast.current.show({
            severity: "success",
            detail: "Rabbitsign subscription updated successfully...!!!",
            life: 3000,
          });
          getRabbitSignSubscription();
        } else {
          setRabbitSignSubscribeLoading(false);
          toast.current.show({
            severity: "warn",
            detail: "Oop's, No payment details found, please provide payment method to subscribe the service.",
            life: 5000,
          });
        }
      })
      .catch((error: any) => {
        setRabbitSignLoading(false);
        setRabbitSignSubscribeLoading(false);

        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while subscribing rabbitsign",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while subscribing rabbitsign",
            life: 3000,
          });
        }
      });
  };
  const handleSkip = () => {
    if (subscribeRef.current) {
      subscribeRef.current.hide();
    }
  };
  const onSubscribeClicked = (e: any) => {
    if (subscribeRef.current) {
      subscribeRef.current.toggle(e.originalEvent);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="subscription-main-page">
        <Header pagename="mysubsciption" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <BillingMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <BillingSidebar pagename="mysubsciption" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row d-flex text-12  p-2">
                  <div className="col-12 mt-4">
                    <div className="card ">
                      <div className="card-header">
                        <div className="row  d-flex  align-items-center w-100">
                          <div className="col-6 ">
                            <b className="fs-5">Service Credits</b>
                          </div>
                          <div className="col-6 d-flex  justify-content-end ">
                            <InputSwitch className="d-flex align-items-center" checked={true} />
                          </div>
                        </div>
                      </div>
                      <div className="card-body history-table">
                        {servicesListLoading ? (
                          <div className="d-flex align-items-center justify-content-center mt-2">
                            <AtkLoader />
                          </div>
                        ) : (
                          <DataTable rows={8} paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink" value={servicesList}>
                            <Column header="Service" field="providerName" style={{ width: "70%" }}></Column>
                            <Column header="Cost Per Consume" body={costTemplate} field="costInCent"></Column>
                            <Column header="Is Available" body={isAvailableTemplate}></Column>
                          </DataTable>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  p-2 mt-3">
                  <div className="col-3 ">
                    <div className="card  subscription-card">
                      <div className="card-body">
                        <div className="row  d-flex  align-items-center ">
                          <div className="col-6 ">
                            <b className="fs-5">Rabbit Sign</b>
                          </div>
                          <div className="col-6 d-flex  justify-content-end ">
                            {rabbitSignSubscribeLoading ? (
                              <div className="d-flex align-items-center justify-content-center my-2">
                                <AtkLoader />
                              </div>
                            ) : (
                              <InputSwitch className="d-flex align-items-center" checked={isRabbitSignSubscribed} onChange={onSubscribeClicked} />
                            )}
                          </div>

                          <>
                            <div className="col-12">
                              <span className="text-26" style={{ color: "#0e9e82" }}>
                                $20/month
                              </span>
                            </div>
                            <div className="col-12">
                              <span className="text-20 ">Get Unlimited E-Signing</span>
                            </div>
                            <div className="col-12 text-14 mt-1" style={{ textAlign: "justify" }}>
                              <span className="">
                                Quickly send the same document for signature over and over. There is no limit on how many templates you can create and
                                use. Quickly send the same document for signature over and over. There is no limit on how many templates you can
                                create and use.
                              </span>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <OverlayPanel ref={subscribeRef} className="menu-panel ">
        <SectionMessage appearance="warning">
          <p>
            <span className=" d-block text-14 text-dark">
              Are you sure you want to {isRabbitSignSubscribed ? "Unsubscribe" : "Subscribe"} Rabbit Sign?
            </span>
          </p>
          <div className="text-end mt-2">
            <AtkButton label="OK" onClick={onRabbitSignSubscribedClicked} className="pf-secondary-btn w-auto me-2" />
            <AtkButton label="Cancel" onClick={handleSkip} className="pf-primary-btn w-auto" />
          </div>
        </SectionMessage>
      </OverlayPanel>
    </>
  );
}
