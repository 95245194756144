import { useNavigate } from "react-router-dom";
import React, { FC, useEffect, useState, useRef } from "react";
import CommonValues from "../../common/utils";
import Header from "../../common/Header";
import NowCertLogo from "../../assets/images/NowCerts.png";
import PfdIcon from "../../assets/images/file.png";
import ScanIcon from "../../assets/images/scan.png";
import HistoryIcon from "../../assets/images/History_B.svg";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import axios from "axios";
import CommissionScanHistory from "./CommissionScanHistory";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import { Skeleton } from "primereact/skeleton";
import SectionMessage from '@atlaskit/section-message';

export default function CommissionScan() {
    const navigate = useNavigate();
    const toast: any = useRef("");
    const [selectedtab, setSelectedTab] = useState(1);
    const [showHistory, setShowHistory] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState(false);
    const [uploadFile, setUploadFile] = useState<any>([]);
    const [uploadFileLimit, setUploadFileLimit] = useState(1);
    const [fileNameTitle, setFileNameTitle] = useState("");
    const [fileName, setFileName] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [insufficientBalance, setInsufficientBalance] = useState(true);

    useEffect(() => {
        const token = CommonValues.GetToken();
        if (token == "" || token == null) {
            CommonValues.Logout(navigate);
        }
        getCardDetails();
    }, []);
    const onTabSelect = (index: any) => {
        if (index == 1) {
            setSelectedTab(index);
            onNewScanClick();
        } else {
            setSelectedTab(index);
            onHistoryClick();
        }
    };
    const onNewScanClick = () => {
        setShowHistory(false);
        setSuccessMessage(false);

    };
    const onHistoryClick = () => {
        setShowHistory(true);
    };
    const onDropHandler = (event: any) => {
        event.preventDefault();
        onFileSelectHandler(event.dataTransfer.files);
    };
    const onFileSelectHandler = (files: any) => {
        setFileName("");
        setFileNameTitle("");
        const isValidated = validateSelectedFiles(files);
        if (!isValidated) {
            setErrorMessage("Please select PDF files only");
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
            return false;
        }

        let fileList = [];
        let totalSize = 0;
        let currentFiles = uploadFile;
        let filename = files[0].name;
        if (filename.length > 50) {
            let shortenString = filename.substring(0, 50).concat("...");
            setFileName(shortenString);
            setFileNameTitle(filename);
        } else {
            setFileName(filename);
            setFileNameTitle(filename);
        }
        for (let i = 0, len = files.length; i < len; i++) {
            fileList.push({
                file: files[i],
                name: files[i].name,
                size: files[i].size,
            });
            totalSize = totalSize + files[i].size;
        }

        if (fileList.length > 0) {
            if (currentFiles.length > 0) {
                for (var i = 0, l = currentFiles.length; i < l; i++) {
                    for (var j = 0; j < fileList.length; j++) {
                        if (currentFiles[i].name === fileList[j].name) {
                            fileList.splice(j, 1);
                        }
                    }
                }
            }
            let allFiles = currentFiles.concat(fileList);
            if (allFiles.length > uploadFileLimit) {
                allFiles = allFiles.slice(0, uploadFileLimit);
                setErrorMessage("Multiple files cannot be uploaded at the same time; please upload a single file");
                setTimeout(() => {
                    setErrorMessage("");
                }, 3000);
            }
            setUploadFile(allFiles);
        }
    };
    const validateSelectedFiles = (files: any) => {
        var regularExpression = /(?:\.([^.]+))?$/;
        var isValid = true;

        for (let index = 0, len = files.length; index < len; index++) {
            const fileExtension: any = regularExpression.exec(files[index].name);
            if (fileExtension === undefined || fileExtension[0] == "") {
                isValid = false;
                break;
            }
            if (fileExtension?.[1].toLowerCase().includes("pdf")) {
                isValid = true;
            } else {
                isValid = false;
                break;
            }
        }
        return isValid;
    };
    const removeFileFromList = () => {
        setUploadFile([]);
        setSuccessMessage(false);
    };
    const createUploadRequest = () => {
        if (uploadFile.length == 0) {
            setTimeout(() => {
                setErrorMessage("Please select at least one file to upload");
            }, 3000);
            return;
        }
        setLoading(true);
        const token = CommonValues.GetToken();
        var FormData = require("form-data");
        var data = new FormData();
        data.append("file", uploadFile[0].file);

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_DataFabric_Url}/api/ncaicommissionstatement/scan`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data
        };

        axios(config)
            .then((response: any) => {
                setLoading(false);
                setUploadFile([]);
                setSuccessMessage(true);
                getCardDetails();
            })
            .catch((error: any) => {
                console.log(error);
                if (error.response != null && error.response.status == 401) {
                    navigate("/");
                    CommonValues.Logout(navigate);
                } else {
                    setErrorMessage("Error while creating the request.");
                    setTimeout(() => {
                        setErrorMessage("");
                    }, 3000);
                    setLoading(false);
                }
            });
    };
    const getCardDetails = () => {
        setCurrentBalanceLoading(true);
        const token = CommonValues.GetToken();
        const config = {
            method: "get",
            url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {
                if (response.data.success) {
                    setCurrentBalance(response.data.currentBalance / 100);
                    setInsufficientBalance(response.data.currentBalance / 100 <= 0);
                }
                setCurrentBalanceLoading(false);
            })
            .catch((error) => {
                setCurrentBalanceLoading(false);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while getting current balance",
                            life: 3000,
                        });
                    }
                } else {
                    toast.current.show({
                        severity: "error",
                        detail: "Unknown error while getting current balance",
                        life: 3000,
                    });
                }
            });
    };
    return (
        <div className="new-quote-main-page">
            <Header />
            <div className="content-wrapper">
                <div className="row  wrapper-main-row mx-auto wrapper-below-div">
                    <div className="col-12">
                        <div className="row middle-header-row">
                            <div className="col-12">
                                <div className="coi-scan-middle-header-col active ms-2">
                                    <img src={NowCertLogo} className="landing-page-img" />
                                    <span className="middle-header-title">Commission Scan</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row main-content-row mx-auto ">
                        <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                            <div className="pt-4 pf-sidebar-block p-0">
                                <span className={selectedtab == 1 ? " pf-sidebar-menu active" : "pf-sidebar-menu show-cursor"} onClick={() => onTabSelect(1)}>
                                    <img src={ScanIcon} className={selectedtab == 1 ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
                                    <span className="sidebar-menu-title">New Commission Scan</span>
                                </span>
                                <span className={selectedtab == 2 ? "pf-sidebar-menu active" : " pf-sidebar-menu show-cursor"} onClick={() => onTabSelect(2)}>
                                    <img src={HistoryIcon} className={selectedtab == 2 ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
                                    <span className="sidebar-menu-title">History </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                            {showHistory ? (
                                <CommissionScanHistory />
                            ) : (
                                <>
                                    <div className="col-lg-12 col-md-12 col-sm-12 main-content-col">
                                        <div className="row client-search-row ">
                                            <div className="col-12 d-flex align-items-center justify-content-end">
                                                <div className="row w-100 d-flex align-items-center justify-content-end">

                                                    <div className="col-7"></div>

                                                    <div className="col-3 text-end">
                                                        <label>
                                                            <b>Current Balance</b>
                                                        </label>
                                                    </div>
                                                    <div className="col-2">
                                                        {currentBalanceLoading ? (
                                                            <div className="d-flex align-items-center  justify-content-center p-2 ">
                                                                <AtkLoader />
                                                            </div>
                                                        ) : (
                                                            <div className="input-group">
                                                                <span className="input-group-text">$</span>
                                                                <AtkTextField
                                                                    className="input-field form-control p-0"
                                                                    type="text"
                                                                    id="text"
                                                                    value={currentBalance}
                                                                    isDisabled={true}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        {currentBalanceLoading ?
                                            <Skeleton className="uploadNowcertscoi my-3" />
                                            :
                                            <>
                                                {insufficientBalance ?
                                                    <div className="uploadNowcertscoi my-3">
                                                        <AtkMessage
                                                            appearance="error"
                                                            messageText="Oop's ! There are not enough funds to complete this transaction." />
                                                    </div>
                                                    : <>
                                                        {successMessage ?
                                                            <div className="uploadNowcertscoi my-3">
                                                                <SectionMessage appearance="success" >
                                                                    <h3>File uploaded successfully!</h3>
                                                                    <p className="text-12">We are processing your file. Please check the <b className="show-cursor" onClick={() => onTabSelect(2)}>History</b> tab to find out the file's current progress status.</p>
                                                                </SectionMessage>
                                                            </div>
                                                            :
                                                            <div
                                                                className="uploadNowcertscoi my-3"
                                                                onDrop={(e) => {
                                                                    onDropHandler(e);
                                                                }}
                                                                onDragOver={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                            >

                                                                <div className="row w-100 ">
                                                                    {uploadFile.length > 0 ? (
                                                                        <div className="col-12">
                                                                            {uploadFile.map((file: any, index: any) => (
                                                                                <div className="show-pdf-icon">
                                                                                    <div className="file-name">
                                                                                        <div className="preview-icon">
                                                                                            <img src={PreviewIcon} />
                                                                                        </div>
                                                                                        <div className="filename">
                                                                                            <div title={fileNameTitle}>{fileName}</div>
                                                                                            <div>{(parseInt(file.size) / 1000).toFixed(0)}KB</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div onClick={() => removeFileFromList()} className="show-cursor">
                                                                                        <img src={RemoveIcon} title="Remove file" />
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <>
                                                                                <div className="row" style={{ textAlign: "center" }}>
                                                                                    <div className="col-12">
                                                                                        <img src={PfdIcon} alt="" width="70" />
                                                                                    </div>

                                                                                    <div className="col-12 mt-4">
                                                                                        <div className="col-12">
                                                                                            <span className="drag-and-drop">Drag & Drop file here</span>
                                                                                        </div>
                                                                                        <div className="col-12 my-2">
                                                                                            <span
                                                                                                style={{
                                                                                                    color: "black",
                                                                                                    fontSize: "large",
                                                                                                }}
                                                                                            >
                                                                                                or
                                                                                            </span>
                                                                                        </div>
                                                                                        <label className="mb-0 show-cursor">
                                                                                            <span className="browse">Browse PDF</span>
                                                                                            <input
                                                                                                type="file"
                                                                                                name="filefield"
                                                                                                accept=".pdf"
                                                                                                id="bulkFileUploader"
                                                                                                style={{ visibility: "hidden", display: "none" }}
                                                                                                onChange={(e) => {
                                                                                                    onFileSelectHandler(e.target.files);
                                                                                                }}
                                                                                            />
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        </>
                                                                    )}

                                                                    <div className="col-12 text-center">
                                                                        {uploadFile.length > 0 && (
                                                                            <div className="d-flex justify-content-center" style={{ width: "100%", padding: "20px" }}>
                                                                                {loading == false ? (
                                                                                    <>
                                                                                        <AtkButtonIconBefore
                                                                                            label="Upload"
                                                                                            className="pf-secondary-btn"
                                                                                            onClick={() => {
                                                                                                createUploadRequest();
                                                                                            }}
                                                                                            title="Upload file"
                                                                                        />
                                                                                    </>
                                                                                ) : (
                                                                                    <div className="d-flex justify-content-center align-items-center p-2">
                                                                                        <AtkLoader></AtkLoader>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {errorMessage.length > 0 ? (
                                                                            <div
                                                                                className="d-flex justify-content-center Acord25-upload-file-error"
                                                                                style={{ width: "100%", padding: "20px 20px 0px" }}
                                                                            >
                                                                                <AtkMessage appearance="error" messageText={errorMessage} />
                                                                            </div>
                                                                        ) : null}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
