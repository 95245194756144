import React, { FC, useEffect, useState, useRef } from "react";
import SectionMessage from "@atlaskit/section-message";
import axios from "axios";
import CommonValues from "../common/utils";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";

export default function Skip(props: any) {
  const [skipButtonLoading, setSkipButtonLoading] = useState(false);
  const navigate = useNavigate();
  const toast: any = useRef("");

  const onOkButtonClick = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/updatewizardsetup?IsWizardSetupDone=${true}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    setSkipButtonLoading(true);
    axios(config)
      .then((response: any) => {
        if (response.data.success) {
          navigate("/landingpage");
          localStorage.setItem("isSetupCompleted", "true")
        }
        setSkipButtonLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while updating wizard setup state";
          }
        } else {
          errorMessage = "Unknown error while  updating wizard setup state";
        }
        toast.current.show({ severity: "error",  detail: { errorMessage }, life: 3000 });

        setSkipButtonLoading(false);
      });
  };
  return (
    <>
      <Toast ref={toast} />

      <SectionMessage appearance="warning">
        <p>
          <span className=" d-block text-14 text-dark">Are you sure you want to skip this setup wizard?</span>
        </p>
        <div className="text-end mt-2">
          {skipButtonLoading ? <AtkLoader /> : <AtkButton label="OK" onClick={onOkButtonClick} className="pf-secondary-btn w-auto me-2" />}
          <AtkButton label="Cancel" onClick={props.onCancelButtonClick} className="pf-primary-btn w-auto" />
        </div>
      </SectionMessage>
    </>
  );
}
