import React, { FC, useEffect, useState, useRef } from "react";

import { Toast } from "primereact/toast";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import SectionMessage from "@atlaskit/section-message";
import AtkButton from "../AtlaskitControls/AtkButton";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import { Dropdown } from "primereact/dropdown";
import AtkCheckbox from "../AtlaskitControls/AtkCheckbox";
import MoreBtn from "../../src/assets/images/continue.png";
import CommonValues from "../common/utils";
import axios from "axios";
import StarIcon from "@atlaskit/icon/glyph/star";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import { Logout } from "../common/utils";
import { Navigate, useNavigate } from "react-router-dom";

export default function HawksoftSetting(props: any) {
  const [heightFull, setHeightFull] = useState(false);
  const [hawksoftLoading, setHawksoftLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hawksoftAgencyID, setHawksoftAgencyID] = useState("");
  const [successMessageHawksoft, setSuccessMessageHawksoft] = useState("");
  const [errorMessageHawksoft, setErrorMessageHawksoft] = useState("");
  const [showHawksoftMessage, setShowHawksoftMessage] = useState(true);
  const [errorMessageHawksoftAgencyId, setErrorMessageHawksoftAgencyId] = useState("");
  const [agencyInputType, setAgencyInputType] = useState("password");
  const [carrierSetting, setCarrierSetting] = useState("");
  const [generalAgencySetting, setGeneralAgencySetting] = useState("");
  const [isBundled, setIsBundled] = useState(true);
  const [activePanel, setActivePanel] = useState<number | null>(0);
  const [hawksoftDataLength, setHawksoftDataLength] = useState(0);
  const navigate = useNavigate();
  const toast: any = useRef("");
  const options = [
    { label: "Company", value: "carrier" },
    { label: "Program", value: "program" },
  ];
  const isActivePanel = activePanel === 1;
  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getHawksoftCreds(false);
  }, []);
  const onShowAgencyId = () => {
    if (agencyInputType === "password") {
      setAgencyInputType("text");
      return;
    }
    setAgencyInputType("password");
  };
  const carrierSettingDropdownClicked = (e: any) => {
    setCarrierSetting(e.target.value);
  };
  const generalAgencySettingDropdownClicked = (e: any) => {
    setGeneralAgencySetting(e.target.value);
  };
  const onCheckboxChange = () => {
    setIsBundled(!isBundled);
  };
  const onMoreButtonClick = () => {
    setHeightFull(!heightFull);
  };
  const onAgencyIdChange = (e: any) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || (re.test(e.target.value) && e.target.value.length <= 15)) {
      setHawksoftAgencyID(e.target.value);
    }
  };
  const onKeyUpdateHawksoftClick = (e: any) => {
    if (e.keyCode == 13) {
      onSaveHawksoftInfoClick();
    }
  };
  const getHawksoftCreds = (val: any) => {
    setLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/hawksoftcredentials`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setLoading(false);
        setHawksoftDataLength(response.data.length);
        if (val) {
          if (response.data.agencyId != null && response.data.agencyId != "") {
            getHawksoftAgentDetails();
          } else {
            toast.current.show({ severity: "info", detail: "Please enter hawksoft AMS credentials", life: 3000 });
          }
        }

        setHawksoftAgencyID(response.data.agencyId);
        if (response.data.isBundled == null) {
          setIsBundled(true);
        } else {
          setIsBundled(response.data.isBundled);
        }
        if (response.data.carrier == null || response.data.carrier == "") {
          setCarrierSetting("program");
        } else {
          setCarrierSetting(response.data.carrier);
        }

        if (response.data.generalAgency == null || response.data.generalAgency == "") {
          setGeneralAgencySetting("carrier");
        } else {
          setGeneralAgencySetting(response.data.generalAgency);
        }

        if (response.data.syncCount > 0) {
          setShowHawksoftMessage(false);
        } else {
          setShowHawksoftMessage(true);
        }

        setSuccessMessageHawksoft("");

        setErrorMessageHawksoft("");
        if (response.data.agencyId != null && response.data.agencyId != "") {
          localStorage.setItem("HawksoftAgencyId", "true");
        } else {
          localStorage.setItem("HawksoftAgencyId", "false");
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the HawkSoft Setting details";
            toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while loading the HawkSoft Setting details";
          toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        }
        setLoading(false);
      });
  };
  const checkValidation = () => {
    let formIsValid = true;
    setErrorMessageHawksoftAgencyId("");
    if (hawksoftAgencyID == "" || hawksoftAgencyID == null) {
      formIsValid = false;
      setErrorMessageHawksoftAgencyId("Please enter agency id");
      return formIsValid;
    }
    return formIsValid;
  };
  const onSaveHawksoftInfoClick = () => {
    if (checkValidation()) {
      setHawksoftLoading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        AgencyId: hawksoftAgencyID,
        Carrier: carrierSetting,
        GeneralAgency: generalAgencySetting,
        IsBundled: isBundled,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/hawksoftinfo`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setHawksoftLoading(false);
          toast.current.show({ severity: "success", detail: "HawkSoft settings updated successfully", life: 3000 });
          setTimeout(() => {
            getHawksoftCreds(false);
          }, 500);
          getHawksoftAgentDetails();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout(navigate);
            } else {
              errorMessage = "Unknown error while updating the HawkSoft Settings";
              toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
            }
          } else {
            errorMessage = "Unknown error while updating the HawkSoft Settings";
            toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
          }
          setHawksoftLoading(false);
        });
    }
  };
  const onHawksoftSelect = () => {
    getHawksoftCreds(true);
  };
  const getHawksoftAgentDetails = () => {
    setLoading(true);
    var token = CommonValues.GetToken();

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_HAWKSOFTAPI}/api/Hawksoft/agentdetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        let agencyData = {
          agencyName: response.data.subAgencyName,
          agencyAddress1: response.data.addressLine1,
          agencyAddress2: response.data.addressLine2,
          agencyCity: response.data.city,
          agencyState: response.data.state,
          agencyZip: response.data.zipcode,
          agencyCountry: response.data.country,
        };
        onSaveAmsAgencySetting(agencyData);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting user details", life: 3000 });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while getting user details", life: 3000 });
        }
      });
  };
  const onSaveAmsAgencySetting = (agencyData: any) => {
    const token = CommonValues.GetToken();
    var data = {
      CompanyId: agencyData.CompanyId,
      AgencyName: agencyData.agencyName,
      Country: agencyData.agencyCountry,
      Address1: agencyData.agencyAddress1,
      Address2: agencyData.agencyAddress2,
      State: agencyData.agencyState,
      Zip: agencyData.agencyZip,
      City: agencyData.agencyCity,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        props.onPrimaryAMSSelected();

        props.getCommonSettings();
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        // setInvalidCredMessage("");

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 404) {
            toast.current.show({ severity: "error", detail: "Invalid ams360 credentials!", life: 3000 });
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while saving the AMS360 settings", life: 3000 });
          }
        }

        // setAmsSettingErrorMessage(errorMessage);
      });
    // }
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="common-setting-block height-large">
        <div className="row ams-header mx-auto">
          <div className="col-6">
            <h4 className="text-20 fw-normal">HawkSoft</h4>
          </div>
          <div className="col-6 text-end">
            <span className="ams-notselected-icon">
              {props.hawksoftSelected ? (
                <span
                  onClick={() => {
                    onHawksoftSelect();
                  }}
                  className="bookmark-history-icon-after-save"
                >
                  <StarFilledIcon label="Bookmarked" />
                </span>
              ) : (
                <span
                  onClick={() => {
                    onHawksoftSelect();
                  }}
                  className="bookmark-history-icon"
                >
                  <StarIcon label="Bookmark" />
                </span>
              )}
            </span>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center mt-2">
            <AtkLoader></AtkLoader>
          </div>
        ) : (
          <>
            <div className="row mx-auto text-12 p-1 ">
              <div
                className={
                  errorMessageHawksoftAgencyId.length > 0 ? "form-group position-relative validation-div mt-2" : "position-relative form-group mt-2"
                }
              >
                <label className="form-label">
                  HawkSoft Agency Id<span className="text-danger">*</span>
                </label>

                <AtkTextField
                  type={agencyInputType}
                  id="hawksoftAgencyID"
                  name="hawksoftAgencyID"
                  onChange={onAgencyIdChange}
                  value={hawksoftAgencyID}
                  onKeyDown={onKeyUpdateHawksoftClick}
                  placeholder="Enter hawksoft agency id"
                  className="input-field"
                  autoComplete="new-password"
                />

                <span className="hawksoft-settings-eye-icon" onClick={onShowAgencyId}>
                  {agencyInputType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                </span>
              </div>
              <div className=" col-md-12  ">
                {errorMessageHawksoftAgencyId != null && errorMessageHawksoftAgencyId != "" ? (
                  <span className="text-danger text-12 ">{errorMessageHawksoftAgencyId}</span>
                ) : null}
              </div>

              <div className=" form-group mt-3">
                <label className="mb-1">Carrier</label>{" "}
                <Dropdown
                  type="text"
                  id="text"
                  className="finance-company-dropdown input-field"
                  value={carrierSetting}
                  onChange={(e: any) => carrierSettingDropdownClicked(e)}
                  options={options}
                />
              </div>

              <div className=" form-group mt-3">
                <label className="mb-1">General Agency</label>{" "}
                <Dropdown
                  type="text"
                  id="text"
                  className="finance-company-dropdown input-field"
                  value={generalAgencySetting}
                  onChange={(e: any) => generalAgencySettingDropdownClicked(e)}
                  options={options}
                />
              </div>
            </div>
            <div className="row mx-auto">
              <div className="form-group col-12 mt-2  ">
                <AtkCheckbox label="Combine packages in bundle" name={isBundled} isChecked={isBundled} onChange={onCheckboxChange} />
              </div>
              <div className="col-12 text-end mt-2">
                {hawksoftLoading ? (
                  <div className=" my-3">
                    <AtkLoader />
                  </div>
                ) : (
                  <AtkButton label="Save" onClick={onSaveHawksoftInfoClick} className="pf-secondary-btn w-auto my-3" />
                )}
              </div>
              {successMessageHawksoft != null && successMessageHawksoft != "" ? (
                <div className="col-md-12 mt-2 mb-2">
                  <AtkMessage appearance="success" messageText={successMessageHawksoft} />
                </div>
              ) : null}
              {/* {heightFull ? (
<> */}
              <div className="col-12 my-1">
                <SectionMessage appearance="warning">
                  <span className=" d-block text-12">It can take up to 15 minutes for your client list to become available.</span>
                </SectionMessage>
              </div>
              {showHawksoftMessage ? (
                <div className="col-md-12 mt-3">
                  <SectionMessage appearance="warning">
                    <p>
                      <a className="text-12" href="https://www.youtube.com/watch?v=6EARTFwMpKM" target="_blank">
                        Please Make Sure Data Sharing Is Enabled In HawkSoft
                      </a>
                    </p>
                  </SectionMessage>
                </div>
              ) : null}
              {/* </>
               ):null} */}
            </div>

            {/* <div className="row mx-auto more-block-row ">
              <div className="col-12 p-0">
                <div className="more-block text-end">
                  <img src={MoreBtn} onClick={onMoreButtonClick} title={heightFull ? "Show less" : "Show more"} />
                </div>
              </div>
            </div> */}
          </>
        )}
      </div>
    </>
  );
}
