import React, { useEffect, useState, useRef } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import StarIcon from "@atlaskit/icon/glyph/star";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import Hawksoft from "../assets/images/hawksoft-logo.svg";
import Vertafore from "../assets/images/vertafore-logo.svg";
import Nowcerts from "../assets/images/nowcerts-logo-dark 1.svg";
import NowcertsCredentials from "./NowcertsCredentials";
import HawksoftCredentials from "./HawksoftCredentials";
import Ams360Credentials from "./Ams360Credentials";
import AmsAgencySetting from "../CommonSettings/AmsAgencySetting";
import AmsAgencySettings from "./AmsAgencySettings";
import Gigcode from "./Gigcode";
import WidgetList from "./WidgetList";
import FinanceCompanySettings from "./FinanceCompanySettings";
import FinanceCompanyList from "./FinanceCompanyList";
import AtkButton from "../AtlaskitControls/AtkButton";
import { OverlayPanel } from "primereact/overlaypanel";
import Skip from "./Skip";
import Button, { ButtonProps } from "@atlaskit/button";
import SkipThisStep from "./SkipThisStep";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import CommonValues from "../common/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { useLocation } from "react-router-dom";
const hostname = window.location.hostname;

interface ParentComponentProps {
  handleChildData: (data: string) => void;
}

export default function PrimaryAmsSetting() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [nowcertsSelected, setNowcertsSelected] = useState(false);
  const [hawksoftSelected, setHawksoftSelected] = useState(false);
  const [vertaforeSelected, setVertaforeSelected] = useState(false);
  const [showGigCode, setShowGigCode] = useState(false);
  const [showWidgetList, setShowWidgetList] = useState(false);
  const [showFinanceCompanySettings, setShowFinanceCompanySettings] = useState(false);
  const [showFinanceCompanyList, setShowFinanceCompanyList] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const skipBtnRef = useRef<OverlayPanel>(null);
  const skipThisStepBtnRef = useRef<OverlayPanel>(null);
  const [premiumSettingsCurrent, setPremiumSettingsCurrent] = useState([]);
  const [isWidgetSelected, setIsWidgetSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAllPages, setShowAllPages] = useState(true);
  const [insertpfsettings, setinsertpfsettings] = useState(false);
  const [tenantIdtopass, SetTenantIdToPass] = useState("");
  const location = useLocation();
  const hostname = window.location.hostname;
  const [credentialsUpdated, setCredentialsUpdated] = useState(false);
  useEffect(() => {
    const isSetupCompleted = localStorage.getItem("isSetupCompleted");
    if (isSetupCompleted == "true") {
      navigate("/landingpage");
    }
    if (hostname === process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) {
      setShowFinanceCompanyList(true);
      updateTenantId(4, false, false, true);
    } else {
      setShowWidgetList(true);
    }
  }, []);

  const onHawksoftAMSSelected = () => {
    setCredentialsUpdated(false);
    setNowcertsSelected(false);
    setHawksoftSelected(!hawksoftSelected);
    setVertaforeSelected(false);
    updateTenantId(2, !hawksoftSelected, false, false);
    setShowGigCode(false);
    setShowWidgetList(false);
    setShowFinanceCompanyList(false);
    setShowFinanceCompanySettings(false);
    setValidationMessage("");
  };
  const onVertaforeAMSSelected = () => {
    setCredentialsUpdated(false);
    setNowcertsSelected(false);
    setHawksoftSelected(false);
    setVertaforeSelected(!vertaforeSelected);
    updateTenantId(3, false, !vertaforeSelected, false);
    setShowGigCode(false);
    setShowWidgetList(false);
    setShowFinanceCompanyList(false);
    setShowFinanceCompanySettings(false);
    setValidationMessage("");
  };
  const onNowcertsAMSSelected = () => {
    setCredentialsUpdated(false);
    setNowcertsSelected(!nowcertsSelected);
    setHawksoftSelected(false);
    setVertaforeSelected(false);
    updateTenantId(4, false, false, !nowcertsSelected);
    setShowGigCode(false);
    setShowWidgetList(false);
    setShowFinanceCompanyList(false);
    setShowFinanceCompanySettings(false);
    setValidationMessage("");
  };
  const updateTenantId = (tenantId: any, hawksoft: any, vertafore: any, nowcerts: any) => {
    // setCredentialsUpdated(true);

    setValidationMessage("");
    if (hawksoft == true || vertafore == true || nowcerts == true) {
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/primaryams?tenantid=${tenantId}`,
        headers: {
          Authorization: `Bearer ${CommonValues.GetToken()}`,
        },
      };
      axios(config)
        .then(() => {
          localStorage.setItem("tenantId", tenantId);
          SetTenantIdToPass(tenantId);
          if ((hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) && nowcerts == true) {
            getMomentumToken();
          }
        })
        .catch((error: any) => {
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status === 401) {
              CommonValues.Logout(navigate);
            } else {
              errorMessage = "Unknown error while setting primary AMS";
            }
          }
        });
    }
  };
  const onSkipCurrentStepButtonClick = () => {
    if (skipThisStepBtnRef.current) {
      skipThisStepBtnRef.current.hide();
    }
    if (!hawksoftSelected && !nowcertsSelected && !vertaforeSelected) {
      setValidationMessage("Primary ams cannot be empty");
    } else if (
      (nowcertsSelected || hawksoftSelected || vertaforeSelected) &&
      !showGigCode &&
      !showWidgetList &&
      !showFinanceCompanyList &&
      !showFinanceCompanySettings
    ) {
      setShowGigCode(true);
    } else if (showGigCode) {
      setShowGigCode(false);
      setShowWidgetList(true);
    } else if (showWidgetList) {
      if (isWidgetSelected) {
        setShowWidgetList(false);
        setShowFinanceCompanyList(true);
      } else {
        onOkButtonClick();
      }
    } else if (showFinanceCompanyList) {
      setShowFinanceCompanyList(false);
      if (premiumSettingsCurrent.length > 0) {
        setShowFinanceCompanySettings(true);
      } else {
        onOkButtonClick();
      }
    } else if (showFinanceCompanySettings) {
      setShowFinanceCompanySettings(false);
      onOkButtonClick();
    }
  };
  const onNextButtonClick = () => {
    // if (!hawksoftSelected && !nowcertsSelected && !vertaforeSelected) {
    //   setValidationMessage("Primary ams cannot be empty");
    // } else if (
    //   (nowcertsSelected || hawksoftSelected || vertaforeSelected) &&
    //   !showGigCode &&
    //   !showWidgetList &&
    //   !showFinanceCompanyList &&
    //   !showFinanceCompanySettings
    // ) {
    //   if (hostname === process.env.REACT_APP_Momentum_Url) {
    //     setShowGigCode(false);
    //     setShowWidgetList(true);
    //   } else {
    //     setShowGigCode(true);
    //   }
    // } else if (showGigCode) {
    //   setShowGigCode(false);
    //   setShowWidgetList(true);
    // } else
    if (showWidgetList) {
      if (isWidgetSelected) {
        setShowWidgetList(false);
        setShowFinanceCompanyList(true);
      } else {
        toast.current.show({ severity: "info", detail: "Please select widget", life: 3000 });
      }
    } else if (showFinanceCompanyList) {
      setShowFinanceCompanyList(false);
      if (premiumSettingsCurrent.length > 0) {
        setShowFinanceCompanySettings(true);
      } else {
        setShowAllPages(false);
        onOkButtonClick();
      }
    } else if (showFinanceCompanySettings) {
      setShowAllPages(false);
      setShowFinanceCompanySettings(false);
      onOkButtonClick();
    }
  };
  const onBackButtonClick = () => {
    // if (!hawksoftSelected && !nowcertsSelected && !vertaforeSelected) {
    //   navigate("/setupwizard");
    // } else if (
    //   (nowcertsSelected || hawksoftSelected || vertaforeSelected) &&
    //   !showGigCode &&
    //   !showWidgetList &&
    //   !showFinanceCompanyList &&
    //   !showFinanceCompanySettings
    // ) {
    //   navigate("/setupwizard");
    // } else if (showGigCode) {
    //   setShowGigCode(false);
    //   setCredentialsUpdated(false);
    // } else
    if (showWidgetList) {
      // if (hostname === process.env.REACT_APP_Momentum_Url) {
      //   setShowGigCode(false);
      //   setCredentialsUpdated(true);
      //   setShowWidgetList(false);
      // }
      // else{
      //   setShowGigCode(true);
      //   setShowWidgetList(false);
      // }
      navigate("/setupwizard");
    } else if (showFinanceCompanyList) {
      if (hostname === process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) {
        setShowFinanceCompanyList(false);
        navigate("/setupwizard");
      } else {
        setShowWidgetList(true);
        setShowFinanceCompanyList(false);
      }
    } else if (showFinanceCompanySettings) {
      setShowFinanceCompanyList(true);
      setShowFinanceCompanySettings(false);
    }
  };
  const onSkipCurrentStepShowPopup = (e: any) => {
    skipThisStepBtnRef.current?.toggle(e);
  };
  const onSkipButtonClick = (e: any) => {
    skipBtnRef.current?.toggle(e);
  };
  const handleSkip = () => {
    if (skipBtnRef.current) {
      skipBtnRef.current.hide();
    }
    if (skipThisStepBtnRef.current) {
      skipThisStepBtnRef.current.hide();
    }
  };
  const onOkButtonClick = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/updatewizardsetup?IsWizardSetupDone=${true}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    setNextButtonLoading(true);
    axios(config)
      .then((response: any) => {
        if (response.data.success) {
          navigate("/landingpage");
          localStorage.setItem("isSetupCompleted", "true");
        }
        setNextButtonLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while updating wizard setup state";
          }
        } else {
          errorMessage = "Unknown error while  updating wizard setup state";
        }
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });

        setNextButtonLoading(false);
      });
  };
  const handlePremiumFinanceCompanySelected = (updatedPremiumSettingsCurrent: any) => {
    setPremiumSettingsCurrent(updatedPremiumSettingsCurrent);
  };
  const handleWidgetSelected = (widgetSelected: any) => {
    setIsWidgetSelected(widgetSelected);
  };
  const handleCredentialsChange = (areCredentialsUpdated: any) => {
    setCredentialsUpdated(areCredentialsUpdated);
  };
  const momentumcheckValidation = () => {
    let userName = localStorage.getItem("userName");

    let formIsValid = true;

    if (userName == "" || userName == null) {
      formIsValid = false;
      // setErrorMessageNcUserName("Please enter username");
    }
    return formIsValid;
  };
  const getMomentumToken = () => {
    if (momentumcheckValidation()) {
      handleCredentialsChange(false);
      let userName = localStorage.getItem("userName");

      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        NCUserName: userName,
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/verifyMomentumCreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          if (response.data != "" && response.data != null) {
            localStorage.setItem("NCToken", response.data.access_token);
            localStorage.setItem("NCAgencyId", response.data.agencyId);
          }
          getNowCertsAgentDetails();

          // handleCredentialsChange(true);

          // toast.current.show({ severity: "success", detail: "NowCerts Settings updated successfully", life: 3000 });
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              // toast.current.show({
              //   severity: "info",
              //   detail: "It look likes you are not registered with NowCerts, Please register first.",
              //   life: 3000,
              // });
            } else {
              // toast.current.show({ severity: "error", detail: "Unknown error while updating the NowCerts Settings", life: 3000 });
            }
          } else {
            // toast.current.show({ severity: "error", detail: "Unknown error while updating the NowCerts Settings", life: 3000 });
          }
          // setNowCertsLoading(false);
        });
    }
  };
  const getNowCertsAgentDetails = () => {
    setLoading(true);
    var token = CommonValues.GetToken();
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    let nowCertsAgencyId = localStorage.getItem("NCAgencyId") ? localStorage.getItem("NCAgencyId") : "";
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/agencyinfo?nowCertsToken=${nowCertsToken}&nowCertsAgencyId=${nowCertsAgencyId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        let agencyData = {
          agencyName: response.data.agencyName,
          agencyAddress1: response.data.addressLine1,
          agencyAddress2: response.data.addressLine2,
          agencyCity: response.data.city,
          agencyState: response.data.state,
          agencyZip: response.data.zipCode,
          agencyCountry: "USA",
          agencyPhone:response.data.phone,
        };
        onSaveAmsAgencySetting(agencyData);
      })
      .catch((error: any) => {
        setLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status == 409) {
            // this.errorType =
            //   "It look likes you are not registered with NowCerts, Please register first.";
          } else {
            errorMessage = "Unknown error while getting Nowcerts agent details";
            // toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
          }
        } else {
          errorMessage = "Unknown error while getting Nowcerts agent details";
          // toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        }
      });
  };
  const onSaveAmsAgencySetting = (agencyData: any) => {
    const token = CommonValues.GetToken();
    var data = {
      CompanyId: agencyData.CompanyId,
      AgencyName: agencyData.agencyName,
      Country: agencyData.agencyCountry,
      Address1: agencyData.agencyAddress1,
      Address2: agencyData.agencyAddress2,
      State: agencyData.agencyState,
      Zip: agencyData.agencyZip,
      City: agencyData.agencyCity,
      Phone: agencyData.agencyPhone,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        setLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 404) {
            // toast.current.show({ severity: "error", detail: "Invalid ams360 credentials!", life: 3000 });
          } else {
            // toast.current.show({ severity: "error", detail: "Unknown error while saving the AMS360 settings", life: 3000 });
          }
        }
      });
  };
  return (
    <div className="startup-page">
      <Toast ref={toast} />
      <Header pagename="setup-start" />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center setup-landing-page">
        <div className="row mx-auto wrapper-below-div setup-height-div px-3 ">
          {/* <div className="col-lg-3  bg-light rounded">
            <div className="">
              <h4 className="text-dark py-2">Primary AMS Settings</h4>
              <div className="col-12 mb-4">
                <div
                  className="d-flex align-items-center wizard-ams-div justify-content-between show-cursor"
                  onClick={() => {
                    onNowcertsAMSSelected();
                  }}
                >
                  <span className="Ams-logo">
                    <img src={Nowcerts} />
                  </span>
                  <span className="ams-name-span">NowCerts</span>
                  {nowcertsSelected ? (
                    <span
                      onClick={() => {
                        onNowcertsAMSSelected();
                      }}
                      className="bookmark-history-icon-after-save show-cursor"
                    >
                      <StarFilledIcon label="Bookmarked" />
                    </span>
                  ) : (
                    <span
                      className="bookmark-history-icon show-cursor"
                      onClick={() => {
                        onNowcertsAMSSelected();
                      }}
                    >
                      <StarIcon label="Bookmark" />
                    </span>
                  )}
                </div>
                {hostname === process.env.REACT_APP_Momentum_Url ? null : (
                  <>
                    <div
                      className="d-flex align-items-center  wizard-ams-div justify-content-between show-cursor"
                      onClick={() => {
                        onHawksoftAMSSelected();
                      }}
                    >
                      <span className="Ams-logo">
                        <img src={Hawksoft} />
                      </span>
                      <span className="ams-name-span">HawkSoft</span>
                      {hawksoftSelected ? (
                        <span
                          onClick={() => {
                            onHawksoftAMSSelected();
                          }}
                          className="bookmark-history-icon-after-save show-cursor"
                        >
                          <StarFilledIcon label="Bookmarked" />
                        </span>
                      ) : (
                        <span
                          className="bookmark-history-icon show-cursor"
                          onClick={() => {
                            onHawksoftAMSSelected();
                          }}
                        >
                          <StarIcon label="Bookmark" />
                        </span>
                      )}
                    </div>

                    <div
                      className="d-flex align-items-center  wizard-ams-div  justify-content-between show-cursor"
                      onClick={() => {
                        onVertaforeAMSSelected();
                      }}
                    >
                      <span className="Ams-logo">
                        <img src={Vertafore} />
                      </span>
                      <span className="ams-name-span">AMS360</span>
                      {vertaforeSelected ? (
                        <span
                          onClick={() => {
                            onVertaforeAMSSelected();
                          }}
                          className="bookmark-history-icon-after-save show-cursor"
                        >
                          <StarFilledIcon label="Bookmarked" />
                        </span>
                      ) : (
                        <span
                          className="bookmark-history-icon show-cursor"
                          onClick={() => {
                            onVertaforeAMSSelected();
                          }}
                        >
                          <StarIcon label="Bookmark" />
                        </span>
                      )}
                    </div>
                  </>
                )}
                {validationMessage.length > 0 ? (
                  <div className="col-12 mt-3">
                    <AtkMessage appearance="warning" messageText={validationMessage} />
                  </div>
                ) : null}
              </div>
            </div>
          </div> */}

          <div className="col  bg-light rounded  position-relative setup-right-side">
            {showAllPages ? (
              <>
                {/* {!nowcertsSelected &&
                !hawksoftSelected &&
                !vertaforeSelected &&
                !showGigCode &&
                !showWidgetList &&
                !showFinanceCompanyList &&
                !showFinanceCompanySettings ? (
                  <h4 className="text-dark py-5 text-center">Please select AMS</h4>
                ) : null}
                {(nowcertsSelected || hawksoftSelected || vertaforeSelected) &&
                !showGigCode &&
                !showWidgetList &&
                !showFinanceCompanyList &&
                !showFinanceCompanySettings ? (
                  <h4 className="text-dark py-2">Please Enter  AMS Credentials So We Can Access Insured Information</h4>
                ) : null}

                {nowcertsSelected && !showGigCode && !showWidgetList && !showFinanceCompanyList && !showFinanceCompanySettings ? (
                  <NowcertsCredentials onCredentialsChange={handleCredentialsChange} />
                ) : null}
                {hawksoftSelected && !showGigCode && !showWidgetList && !showFinanceCompanyList && !showFinanceCompanySettings ? (
                  <HawksoftCredentials onCredentialsChange={handleCredentialsChange} />
                ) : null}
                {vertaforeSelected && !showGigCode && !showWidgetList && !showFinanceCompanyList && !showFinanceCompanySettings ? (
                  <Ams360Credentials onCredentialsChange={handleCredentialsChange} />
                ) : null}

                {(nowcertsSelected || hawksoftSelected || vertaforeSelected) &&
                !showGigCode &&
                !showWidgetList &&
                !showFinanceCompanyList &&
                !showFinanceCompanySettings ? (
                  <>
                    <h4 className="text-dark py-2">Please Enter Agency AMS Credentials So We Can Access Insured Information </h4>
                    <AmsAgencySettings tenantIdtopass={tenantIdtopass} credentialsUpdated={credentialsUpdated} />
                  </>
                ) : null}

                {showGigCode ? (
                  <>
                    <h4 className="text-dark py-2">Do you have GigCode?</h4>
                    <Gigcode />
                  </>
                ) : null} */}

                {showWidgetList ? (
                  <>
                    <h4 className="text-dark py-2">Are you interested in which widget?</h4>
                    <WidgetList onWidgetSelectedUpdate={handleWidgetSelected} />
                  </>
                ) : null}

                {showFinanceCompanyList ? (
                  <>
                    <h4 className="text-dark py-2">Please select premium finance companies</h4>
                    <FinanceCompanyList onPremiumSettingsUpdate={handlePremiumFinanceCompanySelected} />
                  </>
                ) : null}

                {showFinanceCompanySettings ? (
                  <>
                    <h4 className="text-dark py-2">Please provide credentials</h4>
                    <FinanceCompanySettings />
                  </>
                ) : null}
              </>
            ) : null}
            <div className="col-12 text-end  setup-btn-position">
              <AtkButton label="Back" onClick={onBackButtonClick} className="pf-secondary-btn w-auto me-2 " />

              <Button onClick={(e: any) => onSkipButtonClick(e)} className="pf-secondary-btn w-auto me-2" shouldFitContainer>
                Skip
              </Button>
              {/* <Button onClick={(e: any) => onSkipCurrentStepShowPopup(e)} className="pf-secondary-btn w-auto me-2" shouldFitContainer>
                Skip this step
              </Button> */}
              {nextButtonLoading ? <AtkLoader /> : <AtkButton label="Next" onClick={onNextButtonClick} className="pf-primary-btn w-auto " />}
            </div>

            <OverlayPanel ref={skipBtnRef} className="menu-panel ">
              <Skip onCancelButtonClick={handleSkip} />
            </OverlayPanel>
            <OverlayPanel ref={skipThisStepBtnRef} className="menu-panel ">
              <SkipThisStep onCancelButtonClick={handleSkip} onSkipCurrentStepButtonClick={onSkipCurrentStepButtonClick} />
            </OverlayPanel>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
