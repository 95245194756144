import { useState, useEffect, useRef } from "react";
import copyImg from "../../assets/images/copy.png";
export default function HistoryDetails(props: any) {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const CopyToClipBoardClicked = () => {
    if (props.data && props.data.quoteNumber) {
      const quoteNumber = props.data.quoteNumber;
      navigator.clipboard.writeText(quoteNumber)
        .then(() => {
          setSuccessMessage("Copied!");
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        })
        .catch(() => {
          setErrorMessage("Failed to copy.");
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
        });
    }
  };

  return (
    <>
     {props.data.targetPartner === "IPFS"
        ? null :
      <table className="  history-details-font ">
        <div className="row ">
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
            <span>Quote Number</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>{props.data && props.data?.quoteNumber}</span>
            {props.data && props.data?.quoteNumber ? (
              <>
                <img src={copyImg} onClick={CopyToClipBoardClicked} className="copy-icon mx-2" alt="" style={{ cursor: "pointer" }} />
                <span className="text-success"> {successMessage}</span>
                <span className="text-danger"> {errorMessage}</span>
              </>
            ) : null}
          </div>

          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Total Premium</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>
              {props.data &&
                (props.data.totalPremium == null || props.data.totalPremium === ""
                  ? parseFloat("0").toLocaleString("en-US", { style: "currency", currency: "USD" })
                  : parseFloat(props.data.totalPremium).toLocaleString("en-US", { style: "currency", currency: "USD" }))}
            </span>
          </div>

          {/* <div className="col-4  border-bottom border-right py-2"></div> */}

          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Down Payment</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>
              {props.data &&
                (props.data.downPayment == null || props.data.downPayment === ""
                  ? parseFloat("0").toLocaleString("en-US", { style: "currency", currency: "USD" })
                  : parseFloat(props.data.downPayment).toLocaleString("en-US", { style: "currency", currency: "USD" }))}
            </span>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Financed Amount</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>
              {props.data &&
                (props.data.amountFinanced == null || props.data.amountFinanced === ""
                  ? parseFloat("0").toLocaleString("en-US", { style: "currency", currency: "USD" })
                  : parseFloat(props.data.amountFinanced).toLocaleString("en-US", { style: "currency", currency: "USD" }))}
            </span>
          </div>
          {/* <div className="col-4  border-bottom py-2"></div> */}

          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Finance Charge</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>
              {props.data &&
                (props.data.financeCharge == null || props.data.financeCharge === ""
                  ? parseFloat("0").toLocaleString("en-US", { style: "currency", currency: "USD" })
                  : parseFloat(props.data.financeCharge).toLocaleString("en-US", { style: "currency", currency: "USD" }))}
            </span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Document Stamp Tax</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>
              {props.data &&
                (props.data.docStampTax == null || props.data.docStampTax === ""
                  ? parseFloat("0").toLocaleString("en-US", { style: "currency", currency: "USD" })
                  : parseFloat(props.data.docStampTax).toLocaleString("en-US", { style: "currency", currency: "USD" }))}
            </span>
          </div>
          {/* <div className="col-4  border-bottom py-2"></div> */}

          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Non Refundable Fee</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>
              {props.data &&
                (props.data.nonRefundableFee == null || props.data.nonRefundableFee === ""
                  ? parseFloat("0").toLocaleString("en-US", { style: "currency", currency: "USD" })
                  : parseFloat(props.data.nonRefundableFee).toLocaleString("en-US", { style: "currency", currency: "USD" }))}
            </span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Number of Installments</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>{props.data && props.data?.numberOfInstallments}</span>
          </div>
          {/* <div className="col-4  border-bottom py-2"></div> */}

          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Installment Amount</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>
              {props.data &&
                (props.data.installmentAmount == null || props.data.installmentAmount === ""
                  ? parseFloat("0").toLocaleString("en-US", { style: "currency", currency: "USD" })
                  : parseFloat(props.data.installmentAmount).toLocaleString("en-US", { style: "currency", currency: "USD" }))}
            </span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Effective APR(%)</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>
              {props.data &&
                (props.data.effectiveAPR == "" || props.data.effectiveAPR == null
                  ? `${parseFloat("0").toFixed(2)}%`
                  : `${parseFloat(props.data.effectiveAPR).toFixed(3)}%`)}
            </span>
          </div>
          {/* <div className="col-4  border-bottom py-2"></div> */}

          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Broker Fee</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>
              {props.data &&
                (props.data.brokerFee == null || props.data.brokerFee === ""
                  ? parseFloat("0").toLocaleString("en-US", { style: "currency", currency: "USD" })
                  : parseFloat(props.data.brokerFee).toLocaleString("en-US", { style: "currency", currency: "USD" }))}
            </span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Other Taxes</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
            <span>
              {props.data &&
                (props.data.otherTaxes == null || props.data.otherTaxes === ""
                  ? parseFloat("0").toLocaleString("en-US", { style: "currency", currency: "USD" })
                  : parseFloat(props.data.otherTaxes).toLocaleString("en-US", { style: "currency", currency: "USD" }))}
            </span>
          </div>
          {/* <div className="col-4  border-bottom py-2"></div> */}

          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold">
            <span>Other Fees</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 d-flex border-bottom align-items-center">
            <span>
              {props.data &&
                (props.data.otherFees == null || props.data.otherFees === ""
                  ? parseFloat("0").toLocaleString("en-US", { style: "currency", currency: "USD" })
                  : parseFloat(props.data.otherFees).toLocaleString("en-US", { style: "currency", currency: "USD" }))}
            </span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2  d-flex align-items-center fw-bold">
            <span>Quote Date</span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2  d-flex align-items-center">
            <span>
              {props.data &&
                (props.data.quoteDate != null && props.data.quoteDate !== ""
                  ? new Date(props.data.quoteDate).toLocaleDateString("en-US", {
                      month: "numeric",
                      day: "numeric",
                      year: "numeric",
                    })
                  : null)}
            </span>
          </div>
          {/* <div className="col-4   py-2"></div> */}
        </div>
      </table>
}
    </>
  );
}
